import Vue from "vue";
import { AxiosError } from "axios";

import { notificationType, notificationGroup } from "./constants";

// TODO Decide on using this helper or calling notify directly...
export function GetNotificationObj(isSuccess: boolean, customTitle: string, customMsg: string) {
  return {
    group: notificationGroup.general,
    type: isSuccess ? notificationType.success : notificationType.error,
    title: customTitle,
    text: customMsg,
  };
}

export function notifyError(title: string, err: AxiosError) {
  if (err.response && err.response.data) {
    var errorMsg = getErrorMessageFromBody(err.response.data);
  } else {
    var errorMsg = err.message;
  }

  Vue.notify({
    group: notificationGroup.general,
    type: notificationType.error,
    title: title,
    text: errorMsg,
    duration: 5000,
  });
}

function getErrorMessageFromBody(body: any): string {
  if (body.msg) {
    return body.msg;
  } else {
    return body;
  }
}
