

































import Vue from "vue";

import { DeviceBatchCreateResult } from "@/constants/api_interfaces";

import IconBase from "@/components/common/IconBase.vue";
import CheckMark from "@/components/common/icons/CheckMark.vue";
import CloseCross from "@/components/common/icons/CloseCross.vue";
import ModalWindow from "@/components/common/ModalWindow.vue";

export default Vue.extend({
  name: "DeviceImportModal",

  components: {
    IconBase,
    CheckMark,
    CloseCross,
    ModalWindow,
  },

  props: {
    createResult: { type: Array as () => DeviceBatchCreateResult[], required: true },
  },

  data() {
    return {
      itemCount: this.createResult.length,
      errorCount: this.createResult.filter(device => {
        return "error" in device;
      }).length,
    };
  },

  methods: {
    getDeviceText(device: DeviceBatchCreateResult) {
      if ("error" in device) {
        return `${device.hardware_id}: ${device.error}`;
      } else return device.hardware_id;
    },
  },
});
