







































































































import Vue from "vue";

import PrometheusAPI from "@/helpers/prometheus_api";
import { hierarchyLevel, notificationType, notificationGroup } from "@/constants/constants";
import { AssetInterface, AssetGroupInterface, AssetTypeInterface } from "@/constants/interfaces";
import { getParentsForSelect } from "@/components/entities_edit/helper_functions";
import { EditAsset, CreateAsset } from "@/constants/api_interfaces";

import CheckRights from "@/components/entities_edit/mixins/CheckRights";
import ModalWindow from "@/components/common/ModalWindow.vue";

export default Vue.extend({
  name: "AssetModal",

  mixins: [CheckRights],

  components: {
    ModalWindow,
  },

  props: {
    entityItem: {
      type: Object as () => AssetInterface,
      required: false,
    },
    isReadonly: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      parentList: [] as AssetGroupInterface[],
      classList: [] as AssetTypeInterface[],
      chosenName: this.entityItem ? this.entityItem.name : null,
      displayName: this.entityItem ? this.entityItem.display_name : null,
      chosenSerial: this.entityItem ? this.entityItem.asset_serial : null,
      chosenParent: this.entityItem ? this.entityItem.asset_group_id : -1,
      chosenClass: this.entityItem ? this.entityItem.asset_type_id : -1,
    };
  },

  computed: {
    modalTitle(): string {
      if (this.isReadonly) return this.$t("asset") as string;
      const mode = !this.entityItem ? "Create new: " : "Edit: ";
      return mode + this.$t("asset");
    },
  },

  methods: {
    onCancel() {
      this.$emit("modal-done");
    },

    onConfirm() {
      if (!this.entityItem) return this.saveEntity();
      else return this.editEntity();
    },

    async saveEntity() {
      let postBody: CreateAsset = {
        name: this.chosenName as string,
        display_name: this.displayName as string,
        asset_serial: this.chosenSerial as string,
        asset_group_id: this.chosenParent as number,
        asset_type_id: this.chosenClass as number,
      };

      await PrometheusAPI.createAsset(postBody);
      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: "Saving success",
      });
      this.$emit("entity-saved");
    },

    async editEntity() {
      let putBody: EditAsset = {};
      if (this.chosenName && this.chosenName !== this.entityItem.name) {
        putBody.name = this.chosenName;
      }
      if (this.displayName && this.displayName !== this.entityItem.display_name) {
        putBody.display_name = this.displayName;
      }
      if (this.chosenSerial && this.chosenSerial !== this.entityItem.asset_serial) {
        putBody.asset_serial = this.chosenSerial;
      }
      if (this.chosenParent !== this.entityItem.asset_group_id) {
        putBody.asset_group_id = this.chosenParent;
      }
      if (this.chosenClass !== this.entityItem.asset_type_id) {
        putBody.asset_type_id = this.chosenClass;
      }

      await PrometheusAPI.editAsset(this.entityItem.asset_id, putBody);
      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: "Editing succeeded",
      });
      this.$emit("entity-saved");
    },
  },

  async mounted() {
    this.parentList = (await getParentsForSelect(
      this,
      hierarchyLevel.assetGroup,
      this.entityItem
    )) as AssetGroupInterface[];

    this.classList = (await PrometheusAPI.getHierarchyNames(
      hierarchyLevel.assetType
    )) as AssetTypeInterface[];
  },
});
