








































































import Vue from "vue";
import { debounce } from "lodash";

import { AssetTypeEditActions } from "@/stores/action_types";
import { AssetTypeEditState } from "@/stores/types";
import { DEFAULT_UNIT } from "@/constants/constants";

import UnitAddModal from "@/components/units/UnitAddModal.vue";

export default Vue.extend({
  name: "TemplateModalHeader",

  components: {
    UnitAddModal,
  },

  props: {},

  data() {
    return {
      state: this.$store.state.assetTypeEdit as AssetTypeEditState,
      isUnitAddModalVisible: false,
    };
  },

  computed: {
    isUnitNew(): boolean {
      if (!this.state.unitName || this.state.unitName === DEFAULT_UNIT.name) return false;

      if (this.state.unitList) {
        return !this.state.unitList.some(unit => unit.name === this.state.unitName);
      }

      return true;
    },

    unit: {
      set: debounce(function(this: Vue, userInput: string) {
        this.$store.dispatch(AssetTypeEditActions.setUnit, userInput);
      }, 250),
      get(): string {
        // better representation if the user cannot edit the value
        if (this.changeMode !== "add") return this.state.compositeUnitName as string;

        return this.state.unitName || "";
      },
    },
    measurePointName: {
      set: debounce(function(this: Vue, userInput: string) {
        this.$store.dispatch(AssetTypeEditActions.setMeasurePointName, userInput);
      }, 250),
      get(): string {
        // better representation if the user cannot edit the value
        if (this.changeMode !== "add") return this.state.compositeMeasurePointName as string;

        if (!this.state.measurePointName) return "";

        return this.state.measurePointName;
      },
    },
    subAssetName: {
      set: debounce(function(this: Vue, userInput: string) {
        this.$store.dispatch(AssetTypeEditActions.setSubAssetName, userInput);
      }, 250),
      get(): string {
        if (!this.state.subAssetName) return "";

        return this.state.subAssetName;
      },
    },
    subAssetSuffix: {
      set: debounce(function(this: Vue, userInput: string) {
        this.$store.dispatch(AssetTypeEditActions.setSubAssetSuffix, userInput);
      }, 250),
      get(): string {
        if (!this.state.subAssetSuffix) return "";

        return this.state.subAssetSuffix;
      },
    },

    isSubAsset() {
      return !!this.state.subAssetName;
    },
    changeMode() {
      return this.state.changeMode;
    },
    unitList() {
      return this.state.unitList;
    },
    unusedMeasurePoints() {
      if (!this.state.measurePointList || !this.state.existingTemplates) return [];

      return this.state.measurePointList.filter(mp => {
        return !this.state
          .existingTemplates!.map(el => el.measure_point_id)
          .includes(mp.measure_point_id);
      });
    },
    isSubAssetSuffixDisabled() {
      return !this.subAssetName || this.changeMode === "edit";
    },
  },

  methods: {
    showUnitAddModal() {
      this.isUnitAddModalVisible = true;
    },
    hideUnitAddModal() {
      this.isUnitAddModalVisible = false;
    },
  },
});
