

















import Vue from "vue";

import { hierarchyLevel } from "@/constants/constants";
import { ConfigRowInterface } from "@/constants/interfaces";

import TitleRow from "./TitleRow.vue";
import ValueRow from "./ValueRow.vue";

export default Vue.extend({
  name: "ConfigTableRow",
  components: {
    TitleRow,
    ValueRow,
  },

  props: {
    configRow: Object as () => ConfigRowInterface,
    hiddenRows: Array as () => String[],
  },

  data: function() {
    return {
      selectedHierarchy: this.$store.state.navHierarchy,
    };
  },

  methods: {
    getColNumber(level: hierarchyLevel) {
      switch (level) {
        case hierarchyLevel.organization:
          return 3;
        case hierarchyLevel.fleet:
          return 4;
        case hierarchyLevel.assetGroup:
          return 5;
        case hierarchyLevel.asset:
          return 6;
      }
    },
  },
});
