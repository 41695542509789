
































import Vue from "vue";

import PrometheusAPI from "@/helpers/prometheus_api";

import { ApplicationMeasureKeys, MeasureKey } from "@/constants/interfaces";
import { notificationType, notificationGroup } from "@/constants/constants";

import EditCell from "@/components/common/EditCell.vue";

export default Vue.extend({
  name: "MeasureKeysTable",

  components: {
    EditCell,
  },

  props: {
    appMkList: {
      type: Array as () => ApplicationMeasureKeys[],
      required: true,
    },
  },

  methods: {
    async onEditDescription(oldMk: MeasureKey, newValue: string) {
      await PrometheusAPI.editMeasureKey({ description: newValue }, oldMk.measure_key_id);
      this.$emit("measurekeyedited");
      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: "Updated description",
      });
    },
  },

  beforeUpdate() {
    for (const app of this.appMkList) {
      app.measure_keys.sort((a, b) => (a.measure_key_id > b.measure_key_id ? 1 : -1));
    }
  },
});
