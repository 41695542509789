import Vue from "vue";

const TOKEN_KEY = "TOKEN_KEY";
const REFRESH_TOKEN_KEY = "REFRESH_TOKEN_KEY";

const AuthService = {
  saveToken(accessToken: string) {
    window.localStorage.setItem(TOKEN_KEY, accessToken);
  },

  getToken() {
    return window.localStorage.getItem(TOKEN_KEY);
  },

  removeToken() {
    window.localStorage.removeItem(TOKEN_KEY);
  },

  saveRefreshToken(refreshToken: string) {
    window.localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  },

  getRefreshToken() {
    return window.localStorage.getItem(REFRESH_TOKEN_KEY);
  },

  removeRefreshToken() {
    window.localStorage.removeItem(REFRESH_TOKEN_KEY);
  },
};

export default AuthService;

export function isCurrentUser(userName: string, vueInstance: Vue): boolean {
  return vueInstance.$store.getters["login/user"].userName === userName;
}
