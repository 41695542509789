


















































import Vue from "vue";
import { isEqual } from "lodash";

import { notificationType, notificationGroup } from "@/constants/constants";

import IconBase from "@/components/common/IconBase.vue";
import SaveDisc from "@/components/common/icons/SaveDisc.vue";
import EditPen from "@/components/common/icons/EditPen.vue";
import CloseCross from "@/components/common/icons/CloseCross.vue";

export default Vue.extend({
  name: "EditCell",

  components: {
    IconBase,
    SaveDisc,
    CloseCross,
    EditPen,
  },

  props: {
    isJsonValue: {
      type: Boolean,
      default: false,
    },
    value: {
      required: true,
    },
    showDeleteBtn: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      width: 150,
      currValue: "",
      isEditMode: false,
    };
  },

  methods: {
    onDelete() {
      this.$emit("deletevalue");
    },

    getParseOriginalValue() {
      if (!this.isJsonValue) return this.value as string;
      else {
        if (typeof this.value === "string") return JSON.stringify(this.value).replace(/["']/g, "");
        else if (this.value === undefined || this.value === null) return "";
        else return JSON.stringify(this.value);
      }
    },

    saveValue() {
      let finalValue = this.currValue.trim();
      if (this.isJsonValue) {
        try {
          finalValue = JSON.parse(finalValue);
        } catch (_) {}
      }

      if (isEqual(finalValue, this.value)) {
        this.$notify({
          group: notificationGroup.general,
          type: notificationType.warn,
          title: "Validation",
          text: "Value has not changed",
        });
      } else {
        this.$emit("savevalue", finalValue);
      }
      this.isEditMode = false;
    },

    editValue() {
      this.currValue = this.getParseOriginalValue();
      this.isEditMode = true;
    },
  },

  mounted() {
    const div = this.$refs.contentDiv as HTMLDivElement;
    this.width = div.getBoundingClientRect().width;
  },
});
