import { Unit } from "./interfaces";

export enum hierarchyLevel {
  assetType = "asset_type",
  productGroup = "product_group",
  organization = "organization",
  fleet = "fleet",
  assetGroup = "asset_group",
  asset = "asset",
}

export enum notificationType {
  success = "success",
  warn = "warn",
  error = "error",
}

export enum notificationGroup {
  general = "GENERAL",
}

export enum PermissionType {
  CreateSettingKey,
}

export enum PageRoutes {
  home = "/",
  configEdit = "/config-edit",
  entitiesEdit = "/entities-edit",
  login = "/login",
  userManagement = "/user-management",
  measurePoints = "/measure-points",
  measureKeys = "/measure-keys",
  settingKeys = "/setting-keys",
  templates = "/templates",
  assetTemplates = "/asset-templates",
  guidedSetup = "/guided-setup",
  telematicDevices = "/telematic-devices",
}

export enum Language {
  defaultLanguage = "en",
  fallbackLanguage = "en",
}

export const DEFAULT_UNIT: Unit = {
  unit_id: -1,
  name: "unknown",
  symbol: " ",
  description: "Default if actual unit is unknown",
};

export enum ValueType {
  number = "number",
  string = "string",
  integer = "integer",
}
