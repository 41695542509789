














import Vue from "vue";

import { ActionTypes } from "@/stores/action_types";
import { hierarchyLevel } from "@/constants/constants";
import PrometheusAPI from "@/helpers/prometheus_api";
import { getHierarchyName } from "@/helpers/schema_helpers";
import { EntityInterface } from "@/constants/interfaces";
import { strSearchItem } from "@/helpers/global_helpers";
import { RootState } from "@/stores/types";

import EntityMenu from "@/components/entities_edit/EntityMenu.vue";
import BaseEntityList from "@/components/entities_edit/entity_list/BaseEntityList.vue";

export default Vue.extend({
  name: "EntitiesView",

  components: {
    EntityMenu,
    BaseEntityList,
  },

  data() {
    return {
      entityList: [] as EntityInterface[],
      hierarchyLevels: hierarchyLevel,
      selectedHierarchy: (this.$store.state as RootState).navHierarchy,
    };
  },

  computed: {
    entityFilter() {
      return this.$store.state.entity.filter;
    },

    navHierarchy() {
      return this.$store.state.navHierarchy;
    },

    entityHierarchyName: function() {
      return getHierarchyName(this.$store.state.navHierarchy);
    },

    filteredEntities: function() {
      const filter = this.entityFilter;
      if (!filter) return this.entityList;
      else {
        return this.entityList.filter(entity => strSearchItem(filter, entity));
      }
    },
  },

  methods: {
    async reloadEntities() {
      if (!this.$store.state.navHierarchy) {
        this.$store.dispatch(ActionTypes.setNavHierarchy, hierarchyLevel.organization);
      }

      this.entityList = (
        await PrometheusAPI.getHierarchyNames(this.$store.state.navHierarchy)
      ).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
    },
  },

  watch: {
    async navHierarchy(newVal, oldVal) {
      if (newVal) {
        this.selectedHierarchy = newVal;
        this.entityList = [];
        this.reloadEntities();
      }
    },
  },

  async mounted() {
    this.reloadEntities();
  },
});
