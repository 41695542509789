import { UserRole } from "@/constants/interfaces";
import { RootState, EntityState } from "../types";

export const getters = {
  /** Checks if the logged in user has edit rights on entity level
   * E.g.: Editing the name of entities and adding new ones
   */
  hasEntityEditRights(state: EntityState, getters: any, rootState: RootState, rootGetters: any) {
    if (!rootGetters["login/isLoggedIn"]) return false;

    const role = rootGetters["login/user"].role as UserRole;
    if (role === UserRole.admin || role === UserRole.engineer) return true;
    else if (role === UserRole.technician) return false;
  },
};
