import Vue from "vue";

import { hierarchyLevel } from "@/constants/constants";
import { RootState } from "@/stores/types";

export default Vue.extend({
  data() {
    return {
      currentHierarchyLevel: (this.$store.state as RootState).navHierarchy,
    };
  },

  methods: {
    isLevelVisible(level: hierarchyLevel) {
      switch (this.currentHierarchyLevel) {
        case hierarchyLevel.assetType:
          return hierarchyLevel.assetType === level;
        case hierarchyLevel.productGroup:
          return hierarchyLevel.productGroup === level;
        case hierarchyLevel.organization:
          return hierarchyLevel.organization === level;
        case hierarchyLevel.asset:
          return true;
        case hierarchyLevel.assetGroup:
          if ([hierarchyLevel.asset, hierarchyLevel.assetType].includes(level)) {
            return false;
          } else return true;
        case hierarchyLevel.fleet:
          if ([hierarchyLevel.organization, hierarchyLevel.fleet].includes(level)) {
            return true;
          } else return false;
      }
    },
  },
});
