









import Vue from "vue";

import TextFilter from "@/components/common/TextFilter.vue";

export default Vue.extend({
  name: "ConfigMenu",
  components: {
    TextFilter,
  },
});
