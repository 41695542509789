


























































































import mixins from "vue-typed-mixins";

import { ActionTypes } from "@/stores/action_types";
import { hierarchyLevel } from "@/constants/constants";
import {
  EntityInterface,
  AssetInterface,
  AssetGroupInterface,
  LockData,
} from "@/constants/interfaces";
import {
  getHierarchyId,
  getHierarchyName,
  getParentLevel,
  getTemplateLevel,
  getParent,
  getClass,
  getChildLevel,
  getHierarchyLevel,
  isLockEntityEditable,
} from "@/helpers/schema_helpers";
import { EntityState } from "@/stores/types";

import CheckRights from "@/components/entities_edit/mixins/CheckRights";
import ViewConfigs from "@/components/entities_edit/mixins/ViewConfigs";
import IconBase from "@/components/common/IconBase.vue";
import WindowUp from "@/components/common/icons/WindowUp.vue";
import WindowDown from "@/components/common/icons/WindowDown.vue";
import CreateEditModal from "@/components/entities_edit/create_edit_modal/CreateEditModal.vue";
import DownloadPrompt from "@/components/entities_edit/entity_list/DownloadPrompt.vue";
import CheckOut from "@/components/entities_edit/entity_list/CheckOut.vue";
import EntityActions from "@/components/entities_edit/entity_list/EntityActions.vue";

const checkoutEntities = [
  hierarchyLevel.organization,
  hierarchyLevel.fleet,
  hierarchyLevel.assetGroup,
  hierarchyLevel.asset,
];

export default mixins(CheckRights, ViewConfigs).extend({
  name: "BaseEntityList",

  components: {
    CreateEditModal,
    DownloadPrompt,
    IconBase,
    WindowUp,
    WindowDown,
    CheckOut,
    EntityActions,
  },

  props: {
    entities: Array as () => EntityInterface[],
  },

  data: function() {
    return {
      isReadyOnly: true,
      isModalVisible: false,
      promptAssetGroup: null as AssetGroupInterface | null,
      entityToEdit: null as EntityInterface | null,
      state: this.$store.state.entity as EntityState,
    };
  },

  computed: {
    entityHierarchy(): hierarchyLevel {
      return this.$store.state.navHierarchy;
    },

    entityHierarchyName(): string {
      return getHierarchyName(this.entityHierarchy);
    },

    parentLevel(): hierarchyLevel | null {
      return getParentLevel(this.entityHierarchy);
    },

    templateLevel(): hierarchyLevel | null {
      return getTemplateLevel(this.entityHierarchy);
    },

    hasAppConfigBtn(): boolean {
      return (
        this.entityHierarchy !== hierarchyLevel.assetType &&
        this.entityHierarchy !== hierarchyLevel.productGroup
      );
    },

    hasTemplateButton(): boolean {
      return this.entityHierarchy === hierarchyLevel.assetType;
    },

    hasMpConfigButton(): boolean {
      return this.entityHierarchy === hierarchyLevel.asset;
    },

    hasButtons(): boolean {
      return this.hasTemplateButton || this.hasMpConfigButton || this.hasAppConfigBtn;
    },

    showCheckOut(): boolean {
      return checkoutEntities.includes(this.entityHierarchy);
    },
  },

  methods: {
    getHierarchyLevel,
    getHierarchyName,
    getHierarchyId,

    getLockClasses(locks: LockData[] | undefined): string {
      let classResult: string[] = [];

      if (locks) {
        classResult.push("locked");
      }

      if (isLockEntityEditable(locks, this)) {
        classResult.push("locked-own");
      }

      return classResult.join(" ");
    },

    getParentName(entity: EntityInterface) {
      const parent = getParent(entity);
      return parent ? parent.name : "";
    },

    getClassName(entity: EntityInterface) {
      const class_ = getClass(entity);
      return class_ ? class_.name : "";
    },

    showModal(entity: EntityInterface) {
      this.isModalVisible = true;
      this.isReadyOnly = !this.canEditEntity(entity);
      this.entityToEdit = entity;
    },

    async hideModal(isModified: boolean = false) {
      this.isModalVisible = false;

      if (isModified) {
        this.$emit("reloadentities");
      }
    },

    getTemplateName(entity: AssetInterface) {
      return entity.asset_type.name;
    },

    hideDownloadPrompt() {
      this.promptAssetGroup = null;
    },

    showParentEntityList(entity: EntityInterface) {
      this.$store.dispatch(ActionTypes.setNavHierarchy, getParentLevel(this.entityHierarchy));
      this.$store.dispatch(ActionTypes.setEntityFilter, this.getParentName(entity));
    },

    showChildEntityList(entity: EntityInterface) {
      this.$store.dispatch(ActionTypes.setNavHierarchy, getChildLevel(this.entityHierarchy));
      this.$store.dispatch(ActionTypes.setEntityFilter, entity.name);
    },

    showTemplateLevel(entity: EntityInterface) {
      this.$store.dispatch(ActionTypes.setNavHierarchy, getTemplateLevel(this.entityHierarchy));
      this.$store.dispatch(ActionTypes.setEntityFilter, this.getClassName(entity));
    },

    downloadSetupFile(assetGroup: AssetGroupInterface) {
      this.promptAssetGroup = assetGroup;
    },
  },
});
