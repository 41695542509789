import Vue from "vue";
import { merge } from "lodash";

export interface DialogOptions {
  html?: boolean;
  okText?: string;
  cancelText?: string;
}

const DEFAULT_CONFIRM_OPTIONS: DialogOptions = {
  cancelText: "Cancel",
  okText: "Confirm",
};

async function confirm(
  this: Vue,
  confirmMessage: string,
  options?: DialogOptions
): Promise<boolean> {
  try {
    await this.$dialog.confirm(confirmMessage, merge({}, DEFAULT_CONFIRM_OPTIONS, options));
    return true;
  } catch (_) {
    return false;
  }
}

export default {
  install: function(Vue: any, options: {}) {
    Vue.prototype.$confirm = confirm;
  },
};
