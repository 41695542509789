import { MeasurePoint, AssetTypeTemplate, Unit } from "@/constants/interfaces";

export interface ModuleState {
  measurePointList: MeasurePoint[] | null;
  existingTemplates: AssetTypeTemplate[] | null;
  compositeMeasurePointName: string | null;
  measurePointName: string | null;
  subAssetName: string | null;
  subAssetSuffix: string | null;
  changeMode: string;
  unitList: Unit[] | null;
  compositeUnitName: string | null;
  unitName: string | null;
}

export const baseState: ModuleState = {
  measurePointList: null,
  existingTemplates: null,
  compositeMeasurePointName: null,
  measurePointName: null,
  subAssetName: null,
  subAssetSuffix: null,
  changeMode: "add",
  unitList: null,
  compositeUnitName: null,
  unitName: null,
};
