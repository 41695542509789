


































































import Vue from "vue";

import { hierarchyLevel } from "@/constants/constants";
import { getHierarchyLevel } from "@/helpers/schema_helpers";
import { EntityInterface } from "@/constants/interfaces";
import ModalWindow from "@/components/common/ModalWindow.vue";

import ProductGroupModal from "@/components/entities_edit/create_edit_modal/ProductGroupModal.vue";
import AssetTypeModal from "@/components/entities_edit/create_edit_modal/AssetTypeModal.vue";
import OrganizationModal from "@/components/entities_edit/create_edit_modal/OrganizationModal.vue";
import FleetModal from "@/components/entities_edit/create_edit_modal/FleetModal.vue";
import AssetGroupModal from "@/components/entities_edit/create_edit_modal/AssetGroupModal.vue";
import AssetModal from "@/components/entities_edit/create_edit_modal/AssetModal.vue";

export default Vue.extend({
  name: "CreateEditModal",

  components: {
    ModalWindow,
    ProductGroupModal,
    AssetTypeModal,
    OrganizationModal,
    FleetModal,
    AssetGroupModal,
    AssetModal,
  },

  props: {
    entityItem: {
      type: Object as () => EntityInterface,
      // if not present this is create mode
      required: false,
    },
    createLevel: String as () => hierarchyLevel,
    isReadonly: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      hierarchyLevel,
      showToken: false,
      token: "",
    };
  },

  methods: {
    assetGroupSaved: function(e?: string) {
      if (e) {
        this.token = e;
        this.showToken = true;
      } else {
        this.$emit("modal-done", true);
      }
    },
    closeTokenModal: function() {
      this.$emit("modal-done", true);
    },
  },

  computed: {
    chosenHierarchy: function() {
      return this.createLevel || getHierarchyLevel(this.entityItem);
    },
  },
});
