
























































import Vue from "vue";

import PrometheusAPI from "@/helpers/prometheus_api";
import { AppliedAssetTemplate, Unit, AppliedSubAssetTemplate } from "@/constants/interfaces";
import { AssetTypeEditState } from "@/stores/types";
import { AssetTypeEditActions } from "@/stores/action_types";
import { CreateUnit } from "@/constants/api_interfaces";
import { DEFAULT_UNIT, notificationGroup, notificationType } from "@/constants/constants";
import { isSubAssetAppliedTemplate } from "@/constants/type_guards";

import ModalWindow from "@/components/common/ModalWindow.vue";

export default Vue.extend({
  name: "AppliedTemplateTableUnitEdit",

  components: {
    ModalWindow,
  },

  props: {
    assetId: {
      type: Number,
      required: true,
    },
    assetTemplate: {
      type: Object as () => AppliedAssetTemplate,
      required: true,
    },
    subAsset: {
      type: Object as () => AppliedSubAssetTemplate,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      state: this.$store.state.assetTypeEdit as AssetTypeEditState,
      isModalVisible: false,
      unitName: "",
      userInputSymbol: "",
      userInputDescription: "",
    };
  },

  computed: {
    isUnitNew(): boolean {
      if (!this.unitName || this.unitName === DEFAULT_UNIT.name) return false;

      if (this.state.unitList) {
        return !this.state.unitList.some(unit => unit.name === this.unitName);
      }

      return true;
    },

    selectedStandardUnit(): Unit | undefined {
      if (!this.unitName || this.unitName === DEFAULT_UNIT.name) return DEFAULT_UNIT;

      if (this.isUnitNew || !this.state.unitList) return;

      return this.state.unitList.find(unit => unit.name === this.unitName);
    },

    unitSymbol: {
      get(): string {
        if (this.isUnitNew) return this.userInputSymbol;

        if (this.selectedStandardUnit) return this.selectedStandardUnit.symbol;

        return DEFAULT_UNIT.symbol;
      },
      set(value: string) {
        this.userInputSymbol = value;
      },
    },

    unitDescription: {
      get(): string {
        if (this.isUnitNew) return this.userInputDescription;

        if (this.selectedStandardUnit) return this.selectedStandardUnit.description || "";

        return DEFAULT_UNIT.description as string;
      },
      set(value: string) {
        this.userInputDescription = value;
      },
    },
  },

  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    onCancel() {
      this.isModalVisible = false;
    },

    async onConfirm() {
      let chosenUnit: Unit | undefined;

      if (this.isUnitNew) {
        chosenUnit = await this.saveNewUnit();
      } else {
        chosenUnit = this.selectedStandardUnit;
      }

      if (chosenUnit) await this.assignUnitToTemplate(chosenUnit);
      this.isModalVisible = false;
    },

    async assignUnitToTemplate(unit: Unit) {
      var toChangeAssetId = this.subAsset ? this.subAsset.asset_id : this.assetId;

      try {
        await PrometheusAPI.changeUnit(
          toChangeAssetId,
          this.assetTemplate.measure_point_id,
          unit.unit_id
        );
      } catch (error) {
        return;
      }
      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: "Assigned unit",
      });
      // TODO pass in the new template to avoid a network request
      this.$emit("update-unit");
    },

    async saveNewUnit() {
      try {
        var newUnit = this.inputCheck();
      } catch (error) {
        this.$notify({
          group: notificationGroup.general,
          type: notificationType.warn,
          title: error.message,
          text: error.text,
          duration: 5000,
        });
        return;
      }
      const confirmation = await this.$confirm(`Do you really want to create a new custom unit?`);
      if (!confirmation) return;

      try {
        var unitFromBackend = await PrometheusAPI.addUnit(newUnit);
      } catch (error) {
        return;
      }
      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: "Saved new unit",
      });
      this.$store.dispatch(AssetTypeEditActions.addUnitToUnitList, unitFromBackend);

      return unitFromBackend;
    },

    inputCheck(): CreateUnit {
      if (!this.unitName) {
        throw {
          name: "InputCheckError",
          message: "Invalid Unit Name",
        };
      }

      if (!this.unitSymbol) {
        throw {
          name: "InputCheckError",
          message: "Invalid Unit Symbol",
        };
      }

      if (this.unitDescription === DEFAULT_UNIT.description) {
        throw {
          name: "InputCheckError",
          message: "Invalid Unit Description",
          text: "Identical description as the 'unknown' unit",
        };
      }

      return {
        name: this.unitName,
        symbol: this.unitSymbol,
        description: this.unitDescription,
      };
    },
  },

  mounted() {
    if (isSubAssetAppliedTemplate(this.assetTemplate)) {
      var maybeUnit = this.subAsset.unit;
    } else {
      var maybeUnit = this.assetTemplate.unit;
    }

    this.unitName = maybeUnit ? maybeUnit.name : DEFAULT_UNIT.name;
  },
});
