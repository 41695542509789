import { ActionTree, Commit } from "vuex";

import { RootState, EntityState } from "@/stores/types";

type context = { commit: Commit; state: EntityState; rootState: RootState };

export const actions: ActionTree<EntityState, RootState> = {
  setEntityFilter({ commit }: context, filterValue: string) {
    commit("setEntityFilter", filterValue);
  },
};
