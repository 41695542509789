import { BackendUserRole } from "@/constants/interfaces";
import { ValueType } from "@/constants/constants";

export function jwtBaseUrlDecode(
  token: string
): {
  identity: string;
  user_claims: { organization: number | null; role: BackendUserRole; user_id: number };
} {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(atob(base64));
}

/**
 * This function takes a JSONfiable item and (recursively) generates a lowercase
 * search string out of the value (ignoring the keys of objects)
 */
function getValueSearchString(item: any): string {
  let finalStore: Array<string> = [""];

  let recursiveKeySearch = function recursiveKeySearch(recObj: { [key: string]: any }): string[] {
    let recStore: Array<string> = [""];

    for (let key in recObj) {
      if (recObj.hasOwnProperty(key)) {
        if (typeof recObj[key] === "object")
          recStore = recStore.concat(recursiveKeySearch(recObj[key]));
        else if (recObj[key]) recStore.push(recObj[key]).toString();
      }
    }

    return recStore;
  };
  if (typeof item === "object") finalStore = recursiveKeySearch(item);
  else if (item) finalStore = [item.toString()];

  return finalStore.join().toLowerCase();
}

/**
 * This function takes a searchQuery and an searchItem and performs a search on the item
 *
 * The search is "AND" based on all space separated terms in the query and case insensitive. E.g.:
 *  - Query: "hello World", item "Hello my world" -> true
 *  - Query: "hello World", item "Hello planet" -> false
 */
export function strSearchItem(searchQuery: string, searchItem: any): boolean {
  return searchQuery
    .toLocaleLowerCase()
    .split(" ")
    .every(subString => {
      return getValueSearchString(searchItem).includes(subString);
    });
}

/**
 * This opens a browser download dialog to download the **data** arraybuffer
 */
export function downloadData(data: any, filename: string) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function parseStringToValue(stringValue: string, valueType: ValueType): string | number {
  if (valueType === ValueType.number) {
    const numValue = parseFloat(stringValue);
    if (isNaN(numValue)) throw TypeError(`Invalid numeric value: ${stringValue}`);

    return numValue;
  } else if (valueType === ValueType.integer) {
    if (stringValue.includes(".")) throw TypeError(`Invalid integer value: ${stringValue}`);

    const numValue = parseInt(stringValue);
    if (isNaN(numValue)) throw TypeError(`Invalid integer value: ${stringValue}`);

    return numValue;
  } else if (valueType === ValueType.string) {
    return stringValue;
  } else {
    throw new TypeError(`Unknown valueType ${valueType}`);
  }
}
