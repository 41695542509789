import Vue from "vue";

import PrometheusAPI from "@/helpers/prometheus_api";
import { downloadData } from "@/helpers/global_helpers";
import { GetNotificationObj } from "@/constants/notifications";

export async function downloadInstallationFile(
  assetGroupId: number,
  assetGroupName: string,
  vueInstance: Vue
) {
  try {
    vueInstance.$notify(
      GetNotificationObj(true, "Download Request Sent", "Generating executable file")
    );
    const data = await PrometheusAPI.installSetupFile(assetGroupId);
    vueInstance.$notify(GetNotificationObj(true, "Ready to download", "Downloading"));
    downloadData(data, `${assetGroupName}_setup.exe`);
    return true;
  } catch (error) {
    vueInstance.$notify(GetNotificationObj(false, "Error", error.message));
    return false;
  }
}
