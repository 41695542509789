










































































import Vue from "vue";

import {
  AssetTypeTemplate,
  AssetTypeInterface,
  ApplicationMeasureKeys,
  SubAssetTemplate,
} from "@/constants/interfaces";
import { AssetTypeEditState } from "@/stores/types";
import PrometheusAPI from "@/helpers/prometheus_api";
import { EditTemplateDetails } from "@/constants/api_interfaces";
import { notificationGroup, notificationType } from "@/constants/constants";

import TemplateModalGeneral, {
  GetMkValue,
} from "@/components/templates/mixins/TemplateModalGeneral";

import ModalWindow from "@/components/common/ModalWindow.vue";
import TemplateModalHeader from "@/components/templates/template_modal/TemplateModalHeader.vue";
import { parseStringToValue } from "@/helpers/global_helpers";

export default Vue.extend({
  name: "TemplateModalEdit",

  mixins: [TemplateModalGeneral],

  components: {
    ModalWindow,
    TemplateModalHeader,
  },

  props: {
    editTemplate: {
      type: Object as () => AssetTypeTemplate,
      required: true,
    },
    appMkList: {
      type: Array as () => ApplicationMeasureKeys[],
      required: true,
    },
    assetType: {
      type: Object as () => AssetTypeInterface,
      required: true,
    },
    editSubAsset: {
      type: Object as () => SubAssetTemplate,
      required: false,
    },
  },

  data() {
    return {
      state: this.$store.state.assetTypeEdit as AssetTypeEditState,
      valueList: [] as GetMkValue[],
      selectedAppId: null as null | number,
    };
  },

  computed: {
    tmpDetails: function() {
      if ("sub_assets" in this.editTemplate) {
        return this.editSubAsset.details;
      } else {
        return this.editTemplate.details;
      }
    },
  },

  methods: {
    isMkCheckDisabled(mkId: number) {
      for (const app of this.tmpDetails) {
        for (const mk of app.template_details) {
          if (mk.measure_key_id === mkId) return true;
        }
      }
      return false;
    },

    isAppCheckboxDisabled(appId: number) {
      return this.tmpDetails!.some(app => app.application_id === appId);
    },

    generatePayload(): EditTemplateDetails {
      let payload = this.valueList
        .filter(item => item.checked)
        .map(mkVal => {
          let value: string | number;
          try {
            value = parseStringToValue(mkVal.value.trim(), mkVal.type_checker);
          } catch (error) {
            throw {
              name: "InputCheckError",
              message: `Invalid value for ${mkVal.measure_key_name}: ${error}`,
            };
          }
          return {
            measure_key_id: mkVal.measure_key_id,
            sub_asset_suffix: this.state.subAssetSuffix || undefined,
            value,
          };
        });

      if (payload.length === 0) {
        throw {
          name: "InputCheckError",
          message: `No ${this.$t("measure_keys")} selected`,
        };
      }

      return payload;
    },

    async editTmp() {
      try {
        var payload = this.generatePayload();
      } catch (error) {
        this.$notify({
          group: notificationGroup.general,
          type: notificationType.error,
          title: error.message,
          duration: 5000,
        });
        return;
      }

      await PrometheusAPI.updateTemplateValue(this.editTemplate.template_id, payload);
      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: `Template edited`,
      });
      this.$emit("modal-save");
    },
  },

  mounted() {
    // prefill inputs
    if (this.tmpDetails && this.editTemplate) {
      for (const app of this.tmpDetails) {
        for (const mk of app.template_details) {
          this.valueList.push({
            ...mk,
            description: mk.measure_key_description,
            application_id: app.application_id,
            checked: true,
            value: String(mk.value),
          });
        }
      }
    }
  },
});
