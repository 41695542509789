import Vue from "vue";

import { EntityInterface, AssetTypeInterface, AssetInterface } from "@/constants/interfaces";
import { ActionTypes } from "@/stores/action_types";

export default Vue.extend({
  methods: {
    async viewConfig(entity: EntityInterface, target: string, e?: MouseEvent) {
      if (!target) {
        const specialKey = e!.metaKey || e!.ctrlKey;
        target = specialKey ? "_blank" : "_self";
      }

      await this.$store.dispatch(ActionTypes.setSelectedEntity, entity);

      window.localStorage.setItem("store", JSON.stringify({ ...this.$store.state }));

      // using timeout to avoid a racing condidtion which made tests fail
      setTimeout(() => {
        window.open(this.$router.resolve("/config-edit").href, target);
      }, 0);
    },

    async viewTemplates(entity: AssetTypeInterface, target: string, e?: MouseEvent) {
      if (!target) {
        const specialKey = e!.metaKey || e!.ctrlKey;
        target = specialKey ? "_blank" : "_self";
      }

      await this.$store.dispatch(ActionTypes.setSelectedEntity, entity);
      window.localStorage.setItem("store", JSON.stringify({ ...this.$store.state }));

      // using timeout to avoid a racing condidtion which made tests fail
      setTimeout(() => {
        window.open(this.$router.resolve("/templates").href, target);
      }, 0);
    },

    async viewMeasureKeyValues(entity: AssetInterface, target: string, e?: MouseEvent) {
      if (!target) {
        const specialKey = e!.metaKey || e!.ctrlKey;
        target = specialKey ? "_blank" : "_self";
      }

      await this.$store.dispatch(ActionTypes.setSelectedEntity, entity);

      // using timeout to avoid a racing condidtion which made tests fail
      setTimeout(() => {
        window.open(this.$router.resolve("/asset-templates").href, target);
      }, 0);
    },
  },
});
