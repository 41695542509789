























import Vue from "vue";

import { User } from "@/constants/interfaces";
import { isCurrentUser } from "../../helpers/auth_services";

export default Vue.extend({
  name: "UsersTable",

  props: {
    users: Array as () => User[],
  },

  methods: {
    isCurUser(userName: string) {
      return isCurrentUser(userName, this);
    },
  },
});
