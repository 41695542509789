



















import Vue from "vue";

import { AssetTypeTemplate, AssetTypeInterface, UserRole } from "@/constants/interfaces";
import { RootState } from "@/stores/types";
import PrometheusAPI from "@/helpers/prometheus_api";
import { strSearchItem } from "@/helpers/global_helpers";

import TemplatesTable from "@/components/templates/TemplatesTable.vue";
import AssetTypeTemplatesHeader from "@/components/templates/AssetTypeTemplatesHeader.vue";

export default Vue.extend({
  name: "Templates",

  components: {
    TemplatesTable,
    AssetTypeTemplatesHeader,
  },

  data() {
    return {
      assetTypeTemplates: [] as AssetTypeTemplate[],
      listFilter: "",
    };
  },

  computed: {
    assetType: function() {
      return (this.$store.state as RootState).selectedEntity as AssetTypeInterface;
    },
    filteredTemplates() {
      const filter = this.listFilter;
      if (!filter) return this.assetTypeTemplates;
      else {
        return this.assetTypeTemplates.filter(template => strSearchItem(filter, template));
      }
    },
    canUserEdit() {
      return this.$store.getters["login/user"].role !== UserRole.technician;
    },
  },

  methods: {
    updateFilter(value: string) {
      this.listFilter = value;
    },

    async updateTemplates() {
      const entity = (this.$store.state as RootState).selectedEntity as AssetTypeInterface;

      this.assetTypeTemplates = await PrometheusAPI.getAssetTemplates(entity.asset_type_id);
    },
  },

  async mounted() {
    await this.updateTemplates();
  },
});
