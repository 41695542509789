import axios, { AxiosError, AxiosResponse } from "axios";

import AuthService from "@/helpers/auth_services";
import store from "@/stores";
import { ActionTypes } from "@/stores/action_types";
import { notifyError } from "@/constants/notifications";

const BASE_URL = process.env.VUE_APP_API_ENDPOINT || "http://localhost:8001";

export class AxiosApiHelper {
  http = axios;

  constructor() {
    axios.defaults.baseURL = BASE_URL;

    this.mount401RefreshTokenInterceptor();
    if (AuthService.getToken()) {
      this.setAuthHeader();
    }
  }

  setAuthHeader(authHeader?: string) {
    const header = authHeader || `Bearer ${AuthService.getToken()}`;

    axios.defaults.headers.common["Authorization"] = header;
  }

  removeAuthHeader() {
    axios.defaults.headers.common["Authorization"] = undefined;
  }

  private mount401RefreshTokenInterceptor() {
    const forwardSuccess = (response: AxiosResponse<any>) => response;

    axios.interceptors.response.use(forwardSuccess, this.errorInterceptor.bind(this));
  }

  private async errorInterceptor(error: AxiosError) {
    if (error.request.status !== 401 || !this.isTokenExpired(error)) {
      notifyError(`Server Error`, error);
      throw error;
    }

    if (!this.shouldIntercept(error)) {
      store.dispatch(ActionTypes.logout);
      notifyError(`Authorization Error`, error);
      throw error;
    }

    await this.setNewAccessToken();
    return this.retryOriginalRequest(error);
  }

  private shouldIntercept(error: AxiosError): boolean {
    let wasRefreshRequest;

    if (error.config.url) {
      wasRefreshRequest = error.config.url.includes("/auth/refresh");
    } else {
      wasRefreshRequest = (error.config as any).__isRetryRequest;
    }
    return !wasRefreshRequest;
  }

  private isTokenExpired(error: AxiosError): boolean {
    if (!error.response) {
      return false;
    } else if (isArrayBuffer(error.response.data)) {
      return jsonArrayBufferToObject(error.response.data).msg === "Token has expired";
    } else if (error.response.data.toString() === "[object Object]") {
      return error.response.data.msg === "Token has expired";
    } else {
      return false;
    }
  }

  private async setNewAccessToken() {
    const refreshToken = AuthService.getRefreshToken();
    const resp = await axios({
      method: "post",
      url: "/auth/refresh",
      data: {},
      headers: { Authorization: `Bearer ${refreshToken}` },
    });
    AuthService.saveToken(resp.data.access_token);
    this.setAuthHeader(`Bearer ${resp.data.access_token}`);
  }

  private async retryOriginalRequest(error: AxiosError) {
    return axios({
      method: error.config.method,
      url: error.config.url,
      data: error.config.data,
      headers: { ...error.config.headers, Authorization: `Bearer ${AuthService.getToken()}` },
    }).then(response => {
      return response;
    });
  }
}

/**
 * Delete this code once this issue is fixed:
 * https://github.com/axios/axios/issues/2434
 */
function jsonArrayBufferToObject(ab: ArrayBuffer): { msg?: string } {
  try {
    const text = String.fromCharCode.apply(null, Array.from(new Uint8Array(ab)));
    if (!text) return {};
    return JSON.parse(text);
  } catch (error) {
    console.warn(`Error converting arraybuffer to object: ${error}`);
    return {};
  }
}

function isArrayBuffer(value: any): boolean {
  const hasArrayBuffer = typeof ArrayBuffer === "function";

  return (
    hasArrayBuffer &&
    (value instanceof ArrayBuffer || toString.call(value) === "[object ArrayBuffer]")
  );
}
