











import Vue from "vue";

import MeasurePointActions from "@/components/measure_points/MeasurePointActions.vue";
import TextFilter from "@/components/common/TextFilter.vue";

export default Vue.extend({
  name: "MeasurePointsHeader",

  components: {
    MeasurePointActions,
    TextFilter,
  },
});
