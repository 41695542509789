
































import Vue from "vue";

export default Vue.extend({
  name: "ModalWindow",

  props: {
    modalTitle: {
      type: String,
      required: true,
    },
    confirmButtonTitle: {
      type: String,
      default: "Confirm",
    },
    maxWidth: {
      type: String,
      required: false,
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    widthStyle() {
      let styleString = "";

      if (this.maxWidth) {
        styleString += `max-width: ${this.maxWidth}; `;
      }

      return styleString;
    },
  },

  methods: {
    onCancel() {
      this.$emit("modal-cancel");
    },
    onConfirm() {
      this.$emit("modal-confirm");
    },
  },
});
