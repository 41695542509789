import { Module } from "vuex";

import { mutations } from "@/stores/entity/mutations";
import { actions } from "@/stores/entity/actions";
import { RootState, EntityState } from "@/stores/types";
import { getters } from "@/stores/entity/getters";

try {
  const storedState = JSON.parse(window.localStorage.getItem("store") as string);
  var baseEntityState: EntityState = storedState.entity;
} catch (_) {
  var baseEntityState: EntityState = {
    filter: "",
  };
}

export { baseEntityState };

export default {
  namespaced: true,
  state: baseEntityState,
  mutations,
  actions,
  getters,
} as Module<EntityState, RootState>;
