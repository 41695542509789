








import Vue from "vue";

import DeviceList from "@/components/telematic_device/DeviceList.vue";
import DeviceImport from "@/components/telematic_device/DeviceImport.vue";

export default Vue.extend({
  name: "TelematicDevices",

  components: {
    DeviceList,
    DeviceImport,
  },
});
