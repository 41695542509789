import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";

import { mutations } from "./mutations";
import { actions } from "./actions";
import { RootState } from "./types";
import login from "./login";
import entity from "./entity";
import assetTypeEdit from "./asset_type_edit";

Vue.use(Vuex);

const defaultState: RootState = {
  entityConfig: null,
  selectedEntity: null,
  isSelectedEntityEditable: false,
  navHierarchy: null,
};

let storageState = {} as RootState;

try {
  storageState = JSON.parse(window.localStorage.getItem("store") as any);
} catch (_) {}

export const storeConfig = {
  // if the localStorage was empty something weird (like null) might be assigned
  // even without throwing an error
  state: storageState || defaultState,
  mutations,
  actions,
  modules: { login, entity, assetTypeEdit },
} as StoreOptions<RootState>;

const store = new Vuex.Store<RootState>(storeConfig);

// Subscribe to store updates
store.subscribe((mutation, state) => {
  // Store the state object as a JSON string in the next tick
  window.setTimeout(() => {
    let storedState = { ...state, assetTypeEdit: {} };
    window.localStorage.setItem("store", JSON.stringify(storedState));
  }, 0);
});
export default store;
