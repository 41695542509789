








import Vue from "vue";

import { User } from "@/constants/interfaces";
import PrometheusAPI from "@/helpers/prometheus_api";
import { strSearchItem } from "@/helpers/global_helpers";

import UsersTable from "@/components/user_management/UsersTable.vue";
import UserHeader from "@/components/user_management/UserHeader.vue";

export default Vue.extend({
  name: "UserView",

  components: {
    UsersTable,
    UserHeader,
  },

  data() {
    return {
      userList: [] as User[],
      listFilter: "",
    };
  },

  computed: {
    filteredUsers: function() {
      const filter = this.listFilter;
      if (!filter) return this.userList;
      else {
        return this.userList.filter(user => strSearchItem(filter, user));
      }
    },
  },

  methods: {
    updateFilter(value: string) {
      this.listFilter = value;
    },
    async updateUsers() {
      this.userList = await PrometheusAPI.getUsers();
    },
  },

  async mounted() {
    await this.updateUsers();
  },
});
