















































































































import Vue from "vue";

import PrometheusAPI from "@/helpers/prometheus_api";
import { AssetTypeTemplate, SubAssetAssetTypeTemplate } from "@/constants/interfaces";
import { notificationType, notificationGroup } from "@/constants/constants";

import CollapseList from "@/mixins/CollapseList";
import TableHeaderNames from "@/components/templates/mixins/TableHeaderNames";
import SubAssetCollapseList from "@/components/templates/mixins/SubAssetCollapseList";
import TemplateDetailsUpdate from "@/components/templates/mixins/TemplateDetailsUpdate";

import TemplateModal from "@/components/templates/template_modal/TemplateModal.vue";
import TemplateDetail from "@/components/templates/TemplateDetail.vue";

export default Vue.extend({
  name: "TemplatesTable",

  mixins: [TableHeaderNames, CollapseList, SubAssetCollapseList, TemplateDetailsUpdate],

  components: {
    TemplateModal,
    TemplateDetail,
  },

  props: {
    templates: { type: Array as () => AssetTypeTemplate[], required: true },
    canUserEdit: { type: Boolean, required: true },
  },

  data() {
    return {
      templateToEdit: null as null | AssetTypeTemplate,
      subAssetToEdit: null as null | SubAssetAssetTypeTemplate,
      changeMode: "",
    };
  },

  methods: {
    deleteTemplate: async function(templateId: number) {
      const confirmMessage = `Do you really want to delete the ${this.$t(
        "measure_point"
      )} template?`;

      if (!(await this.$confirm(confirmMessage))) return;

      try {
        await PrometheusAPI.deleteTemplate(templateId);
      } catch (_) {
        return;
      }
      this.$emit("delete-template");
      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: `Deleted template`,
      });
    },

    editTemplate: async function(
      template: AssetTypeTemplate,
      subAsset?: SubAssetAssetTypeTemplate
    ) {
      this.changeMode = "edit";
      this.templateToEdit = template;
      this.subAssetToEdit = subAsset || null;
    },

    copyTemplate: async function(template: AssetTypeTemplate, subAsset: SubAssetAssetTypeTemplate) {
      this.changeMode = "copy";
      this.templateToEdit = template;
      this.subAssetToEdit = subAsset;
    },

    hideModal() {
      this.templateToEdit = null;
    },

    saveModal() {
      this.templateToEdit = null;
      this.$emit("edit-template");
    },
  },
});
