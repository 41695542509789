






























import Vue from "vue";

import { ConfigRowInterface, ApplicationSettingValues } from "@/constants/interfaces";
import { hierarchyLevel } from "@/constants/constants";
import { RootState } from "@/stores/types";

import LevelVisible from "@/components/config_edit/config_table/LevelVisible";
import { getConfigTableSettings } from "@/components/config_edit/config_table/configuration";

import ConfigTableRow from "./ConfigTableRow.vue";

export default Vue.extend({
  name: "ConfigTable",

  mixins: [LevelVisible],

  components: {
    ConfigTableRow,
  },

  props: {
    settings: {
      type: Array as () => ApplicationSettingValues[],
      required: true,
    },
  },

  data: function() {
    return {
      hierarchyLevel,
      showModal: false,
      state: this.$store.state as RootState,
      hiddenRows: [] as string[],
      selectedHierarchy: this.$store.state.navHierarchy,
    };
  },

  computed: {
    configArray: function() {
      if (!this.settings) return [];
      else return getConfigTableSettings(this.settings);
    },
  },

  methods: {
    rowKey(row: ConfigRowInterface): string {
      if ("setting_key_id" in row) {
        return `${row.setting_key_id}-${JSON.stringify(row.values)}`;
      } else {
        return row.app.name;
      }
    },

    onCancelModify: function() {
      this.showModal = false;
    },

    onToggleRow: function(targetRow: ConfigRowInterface) {
      let index = this.hiddenRows.indexOf(targetRow.key);
      if (index === -1) {
        this.hiddenRows.push(targetRow.key);
      } else {
        this.hiddenRows.splice(index, 1);
      }
    },

    isHiddenClass: function(row: ConfigRowInterface) {
      let isHidden: boolean;

      isHidden = this.hiddenRows.some(item => row.type !== "application" && item === row.app.name);

      return isHidden ? "collapsed" : "";
    },
  },
});
