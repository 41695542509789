






































import Vue from "vue";

import { ActionTypes } from "@/stores/action_types";
import { EntityInterface } from "@/constants/interfaces";
import { getHierarchyLevel, getHierarchyName } from "@/helpers/schema_helpers";
import { PageRoutes } from "@/constants/constants";

export default Vue.extend({
  name: "AppHeader",

  data() {
    return {
      selectedEntity: this.$store.state.selectedEntity as EntityInterface | null,
      isOnConfigEditPage: this.$router.currentRoute.path === PageRoutes.configEdit,
    };
  },

  watch: {
    async $route(newVal, oldVal) {
      this.isOnConfigEditPage = newVal.path === PageRoutes.configEdit;
    },
  },

  computed: {
    entityLevel() {
      return this.selectedEntity ? getHierarchyName(getHierarchyLevel(this.selectedEntity)) : "-";
    },
    entityName() {
      return this.selectedEntity ? this.selectedEntity.name : "-";
    },
    isLoggedIn: function() {
      return this.$store.getters["login/isLoggedIn"];
    },
    user: function() {
      if (!this.isLoggedIn) return false;
      return this.$store.getters["login/user"];
    },
  },

  methods: {
    logout() {
      this.$store.dispatch(ActionTypes.logout);
    },
  },
});
