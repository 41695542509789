



























































import Vue from "vue";

import { PageRoutes } from "@/constants/constants";
import { UserRole } from "@/constants/interfaces";

import HierarchyNestSideBar from "@/components/common/side_bar/HierarchyNestSideBar.vue";

export default Vue.extend({
  components: {
    HierarchyNestSideBar,
  },
  data() {
    return {
      routes: PageRoutes,
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters["login/isLoggedIn"];
    },
    isAdmin() {
      if (!this.isLoggedIn) return false;

      return this.$store.getters["login/user"].role === UserRole.admin;
    },
  },
  methods: {
    isActive(item: PageRoutes | "entities") {
      const route_path = this.$route.path as PageRoutes;

      if (item === "entities") {
        return [
          PageRoutes.entitiesEdit,
          PageRoutes.configEdit,
          PageRoutes.templates,
          PageRoutes.assetTemplates,
        ].includes(route_path);
      }

      return route_path === item;
    },
  },
});
