






































import Vue from "vue";

import PrometheusAPI from "@/helpers/prometheus_api";
import { SettingKey, ApplicationSettingKeys } from "@/constants/interfaces";
import { notificationType, notificationGroup } from "@/constants/constants";

import EditCell from "@/components/common/EditCell.vue";

export default Vue.extend({
  name: "SettingKeysTable",

  components: {
    EditCell,
  },

  props: {
    settingKeyApps: {
      type: Array as () => ApplicationSettingKeys[],
      required: true,
    },
  },

  methods: {
    async editDescription(oldSk: SettingKey, newValue: string) {
      await PrometheusAPI.editSettingKey({ description: newValue }, oldSk.setting_key_id);

      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        text: "Edited description",
      });

      this.$emit("updatelist");
    },
  },

  beforeUpdate() {
    for (const app of this.settingKeyApps) {
      app.setting_keys.sort((a, b) => (a.setting_key_id > b.setting_key_id ? 1 : -1));
    }
  },
});
