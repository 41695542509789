





























import Vue from "vue";

export default Vue.extend({
  props: {
    type: {
      type: String as () => "button" | "icon",
      default: "icon",
    },
    iconName: {
      type: String,
      default: "box",
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },

  data() {
    return {
      buttonStyle: { width: `${this.width * 1.5}px`, height: `${this.height * 1.5}px` },
    };
  },
});
