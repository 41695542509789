











































































import Vue from "vue";

import ModalWindow from "@/components/common/ModalWindow.vue";

import PrometheusAPI from "@/helpers/prometheus_api";
import { notificationType, notificationGroup } from "@/constants/constants";
import { AssetTypeInterface } from "@/constants/interfaces";
import { CreateAssetType, EditAssetType } from "@/constants/api_interfaces";

export default Vue.extend({
  name: "AssetTypeModal",

  components: {
    ModalWindow,
  },

  props: {
    entityItem: {
      type: Object as () => AssetTypeInterface,
    },
    isReadonly: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      chosenName: this.entityItem ? this.entityItem.name : null,
      chosenType: this.entityItem ? this.entityItem.asset_type : null,
      chosenModelName: this.entityItem ? this.entityItem.model_name : null,
      chosenModelManufacturer: this.entityItem ? this.entityItem.model_manufacturer : null,
      chosenSerial: this.entityItem ? this.entityItem.model_serial : null,
    };
  },

  computed: {
    modalTitle(): string {
      if (this.isReadonly) return this.$t("asset_type") as string;
      const mode = !this.entityItem ? "Create new: " : "Edit: ";
      return mode + this.$t("asset_type");
    },
  },

  methods: {
    onCancel() {
      this.$emit("modal-done");
    },

    onConfirm() {
      if (!this.entityItem) return this.saveEntity();
      else return this.editEntity();
    },

    async saveEntity() {
      let postBody: CreateAssetType = {
        name: this.chosenName as string,
        model_name: this.chosenModelName as string,
        asset_type: this.chosenType as string,
      };

      if (this.chosenSerial) {
        postBody.model_serial = this.chosenSerial;
      }
      if (this.chosenModelManufacturer) {
        postBody.model_manufacturer = this.chosenModelManufacturer;
      }

      await PrometheusAPI.createAssetType(postBody);
      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: "Saving success",
      });
      this.$emit("entity-saved");
    },

    async editEntity() {
      let putBody: EditAssetType = {};
      if (this.chosenName && this.chosenName !== this.entityItem.name) {
        putBody.name = this.chosenName;
      }
      if (this.chosenType && this.chosenType !== this.entityItem.asset_type) {
        putBody.asset_type = this.chosenType;
      }
      if (this.chosenModelName && this.chosenModelName !== this.entityItem.model_name) {
        putBody.model_name = this.chosenModelName;
      }
      if (this.chosenSerial && this.chosenSerial !== this.entityItem.model_serial) {
        putBody.model_serial = this.chosenSerial;
      }
      if (
        this.chosenModelManufacturer &&
        this.chosenModelManufacturer !== this.entityItem.model_manufacturer
      ) {
        putBody.model_manufacturer = this.chosenModelManufacturer;
      }

      await PrometheusAPI.editAssetType(this.entityItem.asset_type_id, putBody);
      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: "Editing succeeded",
      });
      this.$emit("entity-saved");
    },
  },
});
