










































import Vue from "vue";

import PrometheusAPI from "@/helpers/prometheus_api";
import { AssetTypeInterface } from "@/constants/interfaces";
import { assetGuidedSetup } from "@/constants/api_interfaces";

import IconBase from "@/components/common/IconBase.vue";
import DeleteIcon from "@/components/common/icons/DeleteIcon.vue";
import AddIcon from "@/components/common/icons/AddIcon.vue";

export default Vue.extend({
  name: "AssetSetup",

  components: {
    IconBase,
    DeleteIcon,
    AddIcon,
  },

  data() {
    return {
      assetTypes: [] as AssetTypeInterface[],
      assetList: [{ assetName: "", assetSerial: "", assetType: -1, index: Date.now() }],
    };
  },

  methods: {
    addNewAsset() {
      this.assetList.push({ assetName: "", assetSerial: "", assetType: -1, index: Date.now() });
    },

    deleteAsset(assetIndex: number) {
      const targetIndex = this.assetList.findIndex(asset => asset.index === assetIndex);
      this.assetList.splice(targetIndex, 1);
    },

    getAssetSetupData() {
      return this.assetList.map(asset => {
        return {
          name: asset.assetName,
          asset_serial: asset.assetSerial,
          asset_type_id: asset.assetType,
        };
      }) as assetGuidedSetup;
    },
  },

  async created() {
    this.assetTypes = await PrometheusAPI.getAssetTypes();
  },
});
