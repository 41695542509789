















































import Vue from "vue";

import { UserRole } from "@/constants/interfaces";
import PrometheusAPI from "@/helpers/prometheus_api";
import { notificationGroup, notificationType } from "@/constants/constants";

import ModalWindow from "@/components/common/ModalWindow.vue";
import TextFilter from "@/components/common/TextFilter.vue";

export default Vue.extend({
  name: "UserHeader",

  components: {
    ModalWindow,
    TextFilter,
  },

  data() {
    return {
      newUserEmail: "",
      newUserPassword: "",
      newUserRole: UserRole.engineer,

      isModalVisible: false,
      userRoles: UserRole,
    };
  },

  methods: {
    addUser() {
      this.isModalVisible = true;
    },

    onCancel() {
      this.newUserEmail = "";
      this.newUserPassword = "";
      this.isModalVisible = false;
    },

    validateUserInput() {
      if (!this.newUserEmail) {
        throw {
          name: "InputCheckError",
          message: "Email field can't be blank",
        };
        return;
      } else if (!this.newUserPassword) {
        throw {
          name: "InputCheckError",
          message: "Password field can't be blank",
        };
        return;
      } else if (this.newUserPassword.length < 16) {
        throw {
          name: "InputCheckError",
          message: "Password must be at least 16 characters long",
        };
        return;
      }
      return;
    },

    async onSave() {
      try {
        this.validateUserInput();
      } catch (error) {
        this.$notify({
          group: notificationGroup.general,
          type: notificationType.warn,
          title: error.message,
          duration: 3000,
        });
        return;
      }

      await PrometheusAPI.saveUser({
        email: this.newUserEmail,
        password: this.newUserPassword,
        role: this.newUserRole,
      });
      this.isModalVisible = false;
      this.$emit("create-user");
    },
  },
});
