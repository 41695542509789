import * as AssetTypeEditTypes from "./asset_type_edit/action_types";

export enum ActionTypes {
  initState = "initState",
  setSelectedEntity = "setSelectedEntity",
  // navigation
  setNavHierarchy = "setNavHierarchy",
  // login module
  setLoggedInStatus = "login/setLoggedInStatus",
  logout = "login/logout",
  // entity module
  setEntityFilter = "entity/setEntityFilter",
}

// eslint-disable-next-line
export import AssetTypeEditActions = AssetTypeEditTypes.ActionTypes;
