













































import Vue from "vue";

import { DeviceInformation, DeviceStatus } from "@/constants/interfaces";
import PrometheusAPI from "@/helpers/prometheus_api";

import TextFilter from "@/components/common/TextFilter.vue";
import AssetGroupCell from "@/components/telematic_device/AssetGroupCell.vue";
import DeviceDropDownCell from "@/components/telematic_device/DeviceDropDownCell.vue";

import { EventBus } from "@/components/telematic_device/event_bus.ts";

export default Vue.extend({
  name: "DeviceList",

  components: {
    TextFilter,
    AssetGroupCell,
    DeviceDropDownCell,
  },

  props: {
    unassignedOnly: { type: Boolean, default: false },
  },

  data: function () {
    return {
      devices: [] as DeviceInformation[],
      moreDevicesAvailable: false,
      currentDevicePaginationPage: 1,
      filterText: "" as string,
      showUnconnectedDevices: false,
    };
  },

  methods: {
    async loadNextDevicePage() {
      const paginatedResult = await PrometheusAPI.getDevices({
        registered_only: !this.showUnconnectedDevices,
        filter_text: this.filterText,
        page: this.currentDevicePaginationPage + 1,
      });

      if (this.currentDevicePaginationPage == 0) {
        this.devices = paginatedResult.results;
      } else {
        this.devices = this.devices.concat(paginatedResult.results);
      }
      this.currentDevicePaginationPage = paginatedResult.page;
      this.moreDevicesAvailable = paginatedResult.pages > paginatedResult.page;
    },
    async updateDevices() {
      this.currentDevicePaginationPage = 0;
      await this.loadNextDevicePage();
    },
    async updateFilter(filterText: string) {
      this.filterText = filterText.toString();
      await this.updateDevices();
    },
    getState(device: DeviceInformation) {
      if (!device.registered_info) {
        return DeviceStatus.Whitelisted;
      } else if (device.registered_info.asset_group) {
        return DeviceStatus.Assigned;
      } else {
        return DeviceStatus.Connected;
      }
    },
    toggleUnconnectedDevices() {
      this.showUnconnectedDevices = !this.showUnconnectedDevices;
      this.updateDevices();
    },
  },

  async mounted() {
    await this.updateDevices();

    EventBus.$on("update-device-list", async () => {
      await this.updateDevices();
    });
  },

  beforeDestroy() {
    EventBus.$off("update-device-list");
  },
});
