













































import Vue from "vue";

import { notificationGroup, notificationType } from "@/constants/constants";
import { CreateDeviceBatch, DeviceBatchCreateResult } from "@/constants/api_interfaces";
import PrometheusAPI from "@/helpers/prometheus_api";

import IconBase from "@/components/common/IconBase.vue";
import SaveDisc from "@/components/common/icons/SaveDisc.vue";
import DeviceImportModal from "@/components/telematic_device/DeviceImportModal.vue";

import { EventBus } from "@/components/telematic_device/event_bus.ts";

export default Vue.extend({
  name: "DeviceImport",

  components: {
    IconBase,
    SaveDisc,
    DeviceImportModal,
  },

  data: function () {
    return {
      isDeviceModalVisible: false,
      importText: "",
      createResult: [] as DeviceBatchCreateResult[],
      deviceTypes: { logbox: "Logbox", linux_client: "Linux Client" },
      selectedDeviceType: "",
    };
  },

  methods: {
    closeModal() {
      this.isDeviceModalVisible = false;
    },
    checkInput(): CreateDeviceBatch {
      const hardware_ids = this.importText.split(",").map((hardware_id) => hardware_id.trim());

      if (!hardware_ids.length) {
        throw {
          name: "InputCheckError",
          message: `No devices selected`,
        };
      }

      if (hardware_ids.some((hardware_id) => !hardware_id)) {
        throw {
          name: "InputCheckError",
          message: `Empty hardware_id present`,
        };
      }

      return { device_type: this.selectedDeviceType, hardware_ids };
    },
    async saveDevices() {
      try {
        var importList = this.checkInput();
      } catch (error) {
        this.$notify({
          group: notificationGroup.general,
          type: notificationType.warn,
          title: error.message,
          duration: 5000,
        });
        return;
      }

      try {
        this.createResult = await PrometheusAPI.createDevicesInBatch(importList);
      } catch (_) {
        return;
      }
      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: "Import finished",
      });
      this.importText = "";
      this.isDeviceModalVisible = true;
      EventBus.$emit("update-device-list");
    },
  },
});
