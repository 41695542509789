







import Vue from "vue";

import {
  ApplicationSettingValues,
  EntityInterface,
  SettingKeyValues,
} from "@/constants/interfaces";
import { RootState } from "@/stores/types";
import PrometheusAPI from "@/helpers/prometheus_api";
import { strSearchItem } from "@/helpers/global_helpers";

import ConfigMenu from "@/components/config_edit/ConfigMenu.vue";
import ConfigTable from "@/components/config_edit/config_table/ConfigTable.vue";

export default Vue.extend({
  name: "ConfigEdit",

  components: {
    ConfigMenu,
    ConfigTable,
  },

  data() {
    return {
      appSettings: [] as ApplicationSettingValues[],
      settingsFilter: "",
    };
  },

  computed: {
    entity: function() {
      return (this.$store.state as RootState).selectedEntity as EntityInterface;
    },
    filteredSettings() {
      const filter = this.settingsFilter;
      if (!filter) return this.appSettings;
      else {
        return this.appSettings.map(app => {
          return {
            ...app,
            setting_keys: this.getFilteredSk(app.setting_keys, filter),
          };
        });
      }
    },
  },

  methods: {
    updateFilter(filterValue: string) {
      this.settingsFilter = filterValue;
    },
    async reloadConfig() {
      this.appSettings = await PrometheusAPI.getConfigForHierarchy(this.entity);
    },

    getFilteredSk(settingKeys: SettingKeyValues, query: string): SettingKeyValues {
      let filteredKeys: SettingKeyValues = {};

      for (const [sKey, skValue] of Object.entries(settingKeys)) {
        if (strSearchItem(query, [sKey, skValue])) {
          filteredKeys[sKey] = skValue;
        }
      }

      return filteredKeys;
    },
  },

  async mounted() {
    await this.reloadConfig();
  },
});
