import Vue from "vue";

import PrometheusAPI from "@/helpers/prometheus_api";
import { hierarchyLevel } from "@/constants/constants";
import { EntityInterface, LockableEntityInterface } from "@/constants/interfaces";
import { getHierarchyId, isLockEntityEditable, getParent } from "@/helpers/schema_helpers";

export async function getParentsForSelect(
  vueInstance: Vue,
  level: hierarchyLevel,
  editItem?: EntityInterface
) {
  // TODO: if the list of parents gets too big the operation should be done in the backend
  const allParents = (await PrometheusAPI.getHierarchyNames(level)) as LockableEntityInterface[];

  const lockedParents = allParents.filter(parent => {
    return isLockEntityEditable(parent.locks, vueInstance);
  });

  const editParent = editItem && getParent(editItem);
  if (
    editParent &&
    !lockedParents.some(ent => getHierarchyId(ent) === getHierarchyId(editParent))
  ) {
    lockedParents.push(editParent);
  }

  return lockedParents;
}
