import Router from "vue-router";

import AuthService from "@/helpers/auth_services";
import store from "@/stores";
import { UserRole } from "@/constants/interfaces";
import { PageRoutes } from "@/constants/constants";

import Home from "@/views/Home.vue";
import MeasurePoints from "@/views/MeasurePoints.vue";
import MeasureKeys from "@/views/MeasureKeys.vue";
import SettingKeys from "@/views/SettingKeys.vue";
import ConfigEdit from "@/views/ConfigEdit.vue";
import Entities from "@/views/Entities.vue";
import Login from "@/views/Login.vue";
import UserManagement from "@/views/UserManagement.vue";
import Templates from "@/views/Templates.vue";
import AssetTemplates from "@/views/AssetTemplates.vue";
import GuidedSetup from "@/views/GuidedSetup.vue";
import TelematicDevices from "@/views/TelematicDevices.vue";

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: PageRoutes.home,
      name: "home",
      component: Home,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: PageRoutes.configEdit,
      name: "configEdit",
      component: ConfigEdit,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: PageRoutes.entitiesEdit,
      name: "entitiesEdit",
      component: Entities,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: PageRoutes.login,
      name: "login",
      component: Login,
    },
    {
      path: PageRoutes.userManagement,
      name: "userManagement",
      component: UserManagement,
      meta: {
        requiresAdmin: true,
      },
    },
    {
      path: PageRoutes.measurePoints,
      name: "measurePoints",
      component: MeasurePoints,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: PageRoutes.measureKeys,
      name: "measureKeys",
      component: MeasureKeys,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: PageRoutes.settingKeys,
      name: "settingKeys",
      component: SettingKeys,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: PageRoutes.templates,
      name: "templates",
      component: Templates,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: PageRoutes.assetTemplates,
      name: "assetTemplates",
      component: AssetTemplates,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: PageRoutes.guidedSetup,
      name: "guidedSetup",
      component: GuidedSetup,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: PageRoutes.telematicDevices,
      name: "telematicDevices",
      component: TelematicDevices,
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!AuthService.getToken()) {
      next({
        path: "/login",
        query: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (store.getters["login/user"].role !== UserRole.admin) {
      next({
        path: "/",
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiredEngineer)) {
    if ([UserRole.admin, UserRole.engineer].includes(store.getters["login/user"].role)) {
      next({
        path: "/",
      });
    } else {
      next();
    }
  } else next();
});

export default router;
