import Vue from "vue";
import {
  AssetTypeTemplate,
  TemplateInterface,
  SubAssetAssetTypeTemplate,
  SingleAssetTypeTemplate,
} from "@/constants/interfaces";

export default Vue.extend({
  data() {
    return {};
  },

  methods: {
    editTemplateAndEmit(
      templateToUpdate: AssetTypeTemplate,
      updatedDetails: TemplateInterface,
      subAssetSuffix?: string
    ) {
      if ("sub_assets" in templateToUpdate) {
        this.updateSubAssetTemplate(templateToUpdate, updatedDetails, subAssetSuffix as string);
      } else {
        this.updateSingleAssetTemplate(templateToUpdate, updatedDetails);
      }

      this.$emit("edit-template", templateToUpdate);
    },

    updateSubAssetTemplate(
      templateToUpdate: SubAssetAssetTypeTemplate,
      updatedDetails: TemplateInterface,
      suffix: string
    ) {
      let subAssetIndex = templateToUpdate.sub_assets.findIndex(
        subAsset => subAsset.name === suffix
      );
      let detailIndex = templateToUpdate.sub_assets[subAssetIndex].details.findIndex(
        app => app.application_id === updatedDetails.application_id
      );
      templateToUpdate.sub_assets[subAssetIndex].details.splice(detailIndex, 1, updatedDetails);
    },

    updateSingleAssetTemplate(
      templateToUpdate: SingleAssetTypeTemplate,
      updatedDetails: TemplateInterface
    ) {
      let detailIdx = templateToUpdate.details.findIndex(
        app => app.application_id === updatedDetails.application_id
      );
      templateToUpdate.details.splice(detailIdx, 1, updatedDetails);
    },
  },
});
