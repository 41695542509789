


























import Vue from "vue";
import { debounce } from "lodash";

import IconBase from "@/components/common/IconBase.vue";
import CloseCross from "@/components/common/icons/CloseCross.vue";

export default Vue.extend({
  name: "TextFilter",

  components: {
    IconBase,
    CloseCross,
  },

  props: {
    filterName: {
      type: String,
      default: "Filter",
    },
    value: String,
  },

  data() {
    return {
      filterValue: this.value || "",
    };
  },

  computed: {
    convertedName: function() {
      return this.filterName
        .toLocaleLowerCase()
        .replace(" ", "-")
        .replace(/[^\w\-]/g, "");
    },

    inputSize: function() {
      return Math.max(this.filterValue.length, 25);
    },
  },

  methods: {
    dispatchFilter: debounce(function(_self: Vue, value: string) {
      _self.$emit("updatefilter", value);
    }, 500),

    filter() {
      this.dispatchFilter(this, this.filterValue);
    },

    clearFilter() {
      this.filterValue = "";
      this.dispatchFilter(this, this.filterValue);
    },
  },

  watch: {
    async value(newVal, oldVal) {
      this.filterValue = newVal;
    },
  },
});
