import { render, staticRenderFns } from "./TemplateModalEdit.vue?vue&type=template&id=5647d3ba&scoped=true&"
import script from "./TemplateModalEdit.vue?vue&type=script&lang=ts&"
export * from "./TemplateModalEdit.vue?vue&type=script&lang=ts&"
import style0 from "./TemplateModalEdit.vue?vue&type=style&index=0&id=5647d3ba&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5647d3ba",
  null
  
)

export default component.exports