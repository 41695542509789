



















































import Vue from "vue";

import ModalWindow from "@/components/common/ModalWindow.vue";

import PrometheusAPI from "@/helpers/prometheus_api";
import { notificationType, notificationGroup } from "@/constants/constants";
import { OrganizationInterface } from "@/constants/interfaces";
import { CreateOrganization, EditOrganization } from "@/constants/api_interfaces";

export default Vue.extend({
  name: "OrganizationModal",
  components: {
    ModalWindow,
  },
  props: {
    entityItem: {
      type: Object as () => OrganizationInterface,
    },
    isReadonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      chosenName: this.entityItem ? this.entityItem.name : null,
      displayName: this.entityItem ? this.entityItem.display_name : null,
      chosenSerial: this.entityItem ? this.entityItem.customer_serial : null,
    };
  },

  computed: {
    modalTitle(): string {
      if (this.isReadonly) return this.$t("organization") as string;
      const mode = !this.entityItem ? "Create new: " : "Edit: ";
      return mode + this.$t("organization");
    },
  },

  methods: {
    onCancel() {
      this.$emit("modal-done");
    },

    onConfirm() {
      if (!this.entityItem) return this.saveEntity();
      else return this.editEntity();
    },

    async saveEntity() {
      let postBody: CreateOrganization = {
        name: this.chosenName as string,
        display_name: this.displayName as string,
        customer_serial: this.chosenSerial as string,
      };

      await PrometheusAPI.createOrganization(postBody);
      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: "Saving success",
      });
      this.$emit("entity-saved");
    },

    async editEntity() {
      let putBody: EditOrganization = {};
      if (this.chosenName && this.chosenName !== this.entityItem.name) {
        putBody.name = this.chosenName;
      }
      if (this.chosenSerial && this.chosenSerial !== this.entityItem.customer_serial) {
        putBody.customer_serial = this.chosenSerial;
      }
      if (this.displayName && this.displayName !== this.entityItem.display_name) {
        putBody.display_name = this.displayName;
      }

      await PrometheusAPI.editOrganization(this.entityItem.organization_id, putBody);
      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: "Editing succeeded",
      });
      this.$emit("entity-saved");
    },
  },
});
