
































import Vue from "vue";
// own packages
import { ActionTypes } from "@/stores/action_types";
import { jwtBaseUrlDecode } from "@/helpers/global_helpers";
import { UserClaims } from "@/constants/interfaces";
import { GetNotificationObj } from "@/constants/notifications";
import PrometheusAPI from "@/helpers/prometheus_api";

export default Vue.extend({
  name: "LoginDialog",
  data: function() {
    return {
      userName: "",
      password: "",
    };
  },
  methods: {
    async loginSubmit(evt: Event) {
      evt.preventDefault();

      try {
        var res = await PrometheusAPI.login(this.userName, this.password);
      } catch (_) {
        this.$notify(GetNotificationObj(false, "Login Fail", "Username or password is wrong."));
        return;
      }
      let userClaims = jwtBaseUrlDecode(res.access_token).user_claims;
      this.$store.dispatch(ActionTypes.setLoggedInStatus, {
        userName: this.userName,
        role: userClaims.role,
        organization: userClaims.organization,
        accessToken: res.access_token,
        refreshToken: res.refresh_token,
      } as UserClaims);
      const redirect = (this.$route.query.nextUrl as string) || "/";
      this.$router.push(redirect);
    },
  },
});
