import { ActionTree, Commit } from "vuex";

import { RootState } from "@/stores/types";
import { AssetTypeEditState } from "@/stores/types";
import { AssetTypeTemplate, Unit } from "@/constants/interfaces";
import PrometheusAPI from "@/helpers/prometheus_api";

type context = { commit: Commit; state: AssetTypeEditState; rootState: RootState };

export const actions: ActionTree<AssetTypeEditState, RootState> = {
  setTemplateData({ commit }: context, template: AssetTypeTemplate) {
    commit("setTemplateData", template);
  },
  setChangeMode({ commit }: context, mode: string) {
    commit("setChangeMode", mode);
  },
  setExistingTemplates({ commit }: context, templates: AssetTypeTemplate[]) {
    commit("setExistingTemplates", templates);
  },
  setMeasurePointName({ commit }: context, name: string) {
    commit("setMeasurePointName", name);
  },
  setSubAssetSuffix({ commit }: context, suffix: string) {
    commit("setSubAssetSuffix", suffix);
  },
  setSubAssetName({ commit }: context, name: string) {
    commit("setSubAssetName", name);
  },
  setUnit({ commit }: context, unit: string) {
    commit("setUnit", unit);
  },
  addUnitToUnitList({ commit }: context, unit: Unit) {
    commit("addUnitToUnitList", unit);
  },
  clearTemplateEdit({ commit }: context) {
    commit("clearTemplateEdit");
  },

  async getMeasurePoints({ commit }: context) {
    commit("setMeasurePoints", await PrometheusAPI.getMeasurePoints());
  },
  async getUnits({ commit }: context) {
    commit("setUnits", await PrometheusAPI.getUnits());
  },
};
