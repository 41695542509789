import Vue from "vue";

import { MeasureKey, ApplicationMeasureKeys } from "@/constants/interfaces";
import { ValueType } from "@/constants/constants";

export interface GetMkValue extends MeasureKey {
  type_checker: ValueType;
  application_id: number;
  checked: boolean;
  value: string;
}

export default Vue.extend({
  props: {
    appMkList: Array as () => ApplicationMeasureKeys[],
  },

  data() {
    return {
      valueList: [] as GetMkValue[],
      selectedAppId: null as null | number,
    };
  },

  computed: {
    mkList(): MeasureKey[] {
      if (this.selectedAppId === null) return [];
      else {
        return this.appMkList.find(appMk => appMk.application_id === this.selectedAppId)!
          .measure_keys;
      }
    },
  },

  methods: {
    isChecked(mkId: number) {
      return this.valueList.some(el => el.measure_key_id === mkId && el.checked);
    },

    onCancel() {
      this.$emit("modal-cancel");
    },

    checkChange(mk: MeasureKey) {
      let found = this.valueList.find(el => el.measure_key_id === mk.measure_key_id);

      if (found) {
        found.checked = !found.checked;
      } else {
        this.valueList.push({
          ...mk,
          application_id: this.selectedAppId as number,
          checked: true,
          value: "",
        });
      }
    },

    inputChange(mk: MeasureKey, event: KeyboardEvent) {
      const target = event.target as HTMLInputElement;
      let found = this.valueList.find(el => el.measure_key_id === mk.measure_key_id);

      if (found) {
        if (!found.value && target.value.trim()) found.checked = true;
        found.value = target.value;
      } else {
        this.valueList.push({
          ...mk,
          application_id: this.selectedAppId as number,
          checked: true,
          value: target.value,
        });
      }
    },

    toggleAppKeys(appId: number, checked: boolean) {
      if (!checked) {
        this.valueList.forEach(item => {
          if (item.application_id === appId) item.checked = false;
        });
      } else {
        this.appMkList
          .find(item => item.application_id === appId)!
          .measure_keys.forEach(mk => {
            let found = this.valueList.find(el => el.measure_key_id === mk.measure_key_id);

            if (found) {
              found.checked = true;
            } else {
              this.valueList.push({
                ...mk,
                application_id: appId,
                checked: true,
                value: "",
              });
            }
          });
      }
    },

    isAppChecked(appId: number) {
      return this.valueList.some(item => item.checked && item.application_id === appId);
    },

    mkValue(mkId: number) {
      const item = this.valueList.find(el => el.measure_key_id === mkId);
      if (!item) return "";

      if (typeof item.value === "object") {
        return JSON.stringify(item.value);
      } else {
        return String(item.value);
      }
    },
  },

  mounted() {
    this.selectedAppId = this.appMkList[0].application_id;
  },
});
