import { ActionTree, Commit } from "vuex";

import { RootState } from "@/stores/types";
import { LoginState } from "@/stores/types";
import { UserClaims } from "@/constants/interfaces";
import AuthService from "@/helpers/auth_services";
import PrometheusAPI from "@/helpers/prometheus_api";
import router from "@/router";

type context = { commit: Commit; state: LoginState; rootState: RootState };

export const actions: ActionTree<LoginState, RootState> = {
  setLoggedInStatus({ commit }: context, userClaims: UserClaims) {
    AuthService.saveToken(userClaims.accessToken);
    AuthService.saveRefreshToken(userClaims.refreshToken);
    PrometheusAPI.setAuthHeader();
    commit("setLoginStatus", userClaims);
  },
  logout({ commit }: context) {
    AuthService.removeToken();
    AuthService.removeRefreshToken();
    PrometheusAPI.removeAuthHeader();
    commit("logout");
    router.push("/login");
  },
};
