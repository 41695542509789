







import Vue from "vue";
// own components
import LoginDialog from "@/components/login/LoginDialog.vue";

export default Vue.extend({
  name: "ConfigEdit",
  components: {
    LoginDialog,
  },
});
