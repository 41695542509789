































import Vue from "vue";

import { DeviceInformation } from "@/constants/interfaces";
import { notificationGroup, notificationType } from "@/constants/constants";
import { downloadData } from "@/helpers/global_helpers";
import PrometheusAPI from "@/helpers/prometheus_api";
import { EventBus } from "@/components/telematic_device/event_bus.ts";

import IconBase from "@/components/common/IconBase.vue";
import VerticalMoreIcon from "@/components/common/icons/VerticalMoreIcon.vue";

export default Vue.extend({
  name: "DeviceDropDownCell",

  props: {
    device: {
      type: Object as () => DeviceInformation,
      required: true,
    },
  },

  components: {
    VerticalMoreIcon,
    IconBase,
  },

  computed: {
    canNotBeRemoved: function () {
      return Boolean(this.device.registered_info);
    },
    canNotBeDetached: function () {
      return !Boolean(this.device.registered_info);
    },
    canNotDownloadFileDump: function () {
      return !(this.device.registered_info && this.device.registered_info.has_dump);
    },
  },

  methods: {
    async downloadFileDump(registeredDevice: DeviceInformation) {
      try {
        var data = await PrometheusAPI.getDeviceDump(registeredDevice.device.device_id);
      } catch (_) {
        return;
      }
      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: `Preparing dump file...`,
      });
      downloadData(data, registeredDevice.device.hardware_id);
    },
    async removeDevice(device: DeviceInformation) {
      await PrometheusAPI.deleteDevice(device.device.device_id);
      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: `Device ${device.device.hardware_id} is deleted.`,
      });
      EventBus.$emit("update-device-list");
    },
    async detachDevice(device: DeviceInformation) {
      const confirmation = await this.$confirm(
        `Are you sure about detaching the device?` +
          `<br> <hr> The current device token will be invalid and the action is not reversible.` +
          `<br> <br> This means the SD card of the device will have to be manually replaced and ` +
          `the device will <b style="color: red">not be able to send ` +
          `data </b> anymore until a service technician has dealt with the matter on site.`,
        { html: true }
      );
      if (confirmation) {
        await PrometheusAPI.detachDevice(device.device.device_id);
        this.$notify({
          group: notificationGroup.general,
          type: notificationType.success,
          title: `Device ${device.device.hardware_id} is now unregistered.`,
        });
        EventBus.$emit("update-device-list");
      }
    },
  },
});
