import { hierarchyLevel } from "@/constants/constants";
import {
  ApplicationSettingValues,
  EntityInterface,
  LoggerApplication,
  UserRole,
  MeasurePoint,
  Omit,
  AssetTypeTemplate,
  AppliedAssetTemplate,
  ApplicationSettingKeys,
  User,
  ApplicationMeasureKeys,
  LockableEntityInterface,
  OrganizationInterface,
  FleetInterface,
  ProductGroupInterface,
  AssetTypeInterface,
  AssetInterface,
  Unit,
  DeviceInformation,
  AssetGroupInterface,
} from "@/constants/interfaces";
import {
  ApiPath,
  EditSettingKeyValuePayload,
  EditSettingKeyPayload,
  SaveEntityResponse,
  AddTemplate,
  editMkPayload,
  CreateOrganization,
  EditOrganization,
  CreateFleet,
  EditFleet,
  CreateAssetGroup,
  EditAssetGroup,
  CreateProductGroup,
  CreateAssetType,
  CreateAsset,
  EditProductGroup,
  EditAsset,
  EditAssetType,
  GuidedSetup,
  CopyTemplateDetails,
  EditTemplateDetails,
  CreateUnit,
  AssetGroupReponse,
  Paginated,
  DeviceBatchCreateResult,
  CreateDeviceBatch,
  DeviceListQuery,
} from "@/constants/api_interfaces";
import { getHierarchyId, getHierarchyEndpoint } from "./schema_helpers";
import { AxiosApiHelper } from "@/helpers/api_helper";

class PrometheusAPI extends AxiosApiHelper {
  constructor() {
    super();
  }

  async login(
    username: string,
    password: string
  ): Promise<{ access_token: string; refresh_token: string }> {
    return (await this.http.post(`/auth/login`, { username, password })).data;
  }

  async getApplications(): Promise<LoggerApplication[]> {
    let response = await this.http.get(`/api/v1/applications`);
    return response.data as LoggerApplication[];
  }

  async getHierarchyNames(hierarchy: hierarchyLevel.asset): Promise<AssetInterface[]>;
  async getHierarchyNames(hierarchy: hierarchyLevel.assetGroup): Promise<AssetGroupInterface[]>;
  async getHierarchyNames(hierarchy: hierarchyLevel.fleet): Promise<FleetInterface[]>;
  async getHierarchyNames(hierarchy: hierarchyLevel.organization): Promise<OrganizationInterface[]>;
  async getHierarchyNames(hierarchy: hierarchyLevel.productGroup): Promise<ProductGroupInterface[]>;
  async getHierarchyNames(hierarchy: hierarchyLevel.assetType): Promise<AssetTypeInterface[]>;
  async getHierarchyNames(hierarchy: hierarchyLevel): Promise<EntityInterface[]>;
  async getHierarchyNames(hierarchy: hierarchyLevel): Promise<EntityInterface[]> {
    return (await this.http.get(`/api/v1/${getHierarchyEndpoint(hierarchy)}`)).data;
  }

  // EntityAppConfig

  async getConfigForHierarchy(entity: EntityInterface): Promise<ApplicationSettingValues[]> {
    return (
      await this.http.get(
        `/api/v1/${getHierarchyEndpoint(entity)}/${getHierarchyId(entity)}/settings`
      )
    ).data;
  }

  async saveConfigSetting(apiPath: ApiPath, modifyConfig: EditSettingKeyValuePayload) {
    await this.http.post(
      `/api/v1/${getHierarchyEndpoint(apiPath.hierarchy_level)}/${apiPath.entity_id}/settings`,
      {
        setting_key_id: modifyConfig.setting_key_id,
        value: modifyConfig.value,
      }
    );
  }

  async deleteConfigSetting(apiPath: ApiPath, settingKeyId: number) {
    await this.http.post(
      `/api/v1/${getHierarchyEndpoint(apiPath.hierarchy_level)}/${apiPath.entity_id}/settings`,
      {
        setting_key_id: settingKeyId,
        value: "deleted",
        deleted: true,
      }
    );
  }

  // entity
  async createProductGroup(newEntity: CreateProductGroup): Promise<ProductGroupInterface> {
    return (await this.http.post(`/api/v1/product-groups`, newEntity)).data;
  }

  async createAssetType(newEntity: CreateAssetType): Promise<AssetTypeInterface> {
    return (await this.http.post(`/api/v1/asset-types`, newEntity)).data;
  }

  async createOrganization(newEntity: CreateOrganization): Promise<OrganizationInterface> {
    return (await this.http.post(`/api/v1/organizations`, newEntity)).data;
  }

  async createFleet(newEntity: CreateFleet): Promise<FleetInterface> {
    return (await this.http.post(`/api/v1/fleets`, newEntity)).data;
  }

  async createAssetGroup(newEntity: CreateAssetGroup): Promise<AssetGroupReponse> {
    return (await this.http.post(`/api/v1/asset-groups`, newEntity)).data;
  }

  async createAsset(newEntity: CreateAsset): Promise<AssetInterface> {
    return (await this.http.post(`/api/v1/assets`, newEntity)).data;
  }

  async editProductGroup(entity_id: number, editedEntity: EditProductGroup): Promise<void> {
    await this.http.put(`/api/v1/product-groups/${entity_id}`, editedEntity);
  }

  async editAssetType(entity_id: number, editedEntity: EditAssetType): Promise<void> {
    await this.http.put(`/api/v1/asset-types/${entity_id}`, editedEntity);
  }

  async editOrganization(entity_id: number, editedEntity: EditOrganization): Promise<void> {
    await this.http.put(`/api/v1/organizations/${entity_id}`, editedEntity);
  }

  async editFleet(entity_id: number, editedEntity: EditFleet): Promise<void> {
    await this.http.put(`/api/v1/fleets/${entity_id}`, editedEntity);
  }

  async editAssetGroup(entity_id: number, editedEntity: EditAssetGroup): Promise<void> {
    await this.http.put(`/api/v1/asset-groups/${entity_id}`, editedEntity);
  }

  async editAsset(entity_id: number, editedEntity: EditAsset): Promise<void> {
    await this.http.put(`/api/v1/assets/${entity_id}`, editedEntity);
  }

  async saveEntity(newEntity: any, level: hierarchyLevel): Promise<SaveEntityResponse> {
    return (await this.http.post(`/api/v1/${getHierarchyEndpoint(level)}`, newEntity)).data;
  }

  async deleteEntity(entityId: number, level: hierarchyLevel): Promise<void> {
    await this.http.delete(`/api/v1/${getHierarchyEndpoint(level)}/${entityId}`);
  }

  // UserManagement
  async getUsers(): Promise<User[]> {
    return (await this.http.get(`/api/v1/users`)).data.results;
  }

  async saveUser(newUser: { email: string; password: string; role: UserRole }): Promise<void> {
    await this.http.post(`/api/v1/users`, newUser);
  }

  // MeasurePoint
  async getMeasurePoints(): Promise<MeasurePoint[]> {
    return (await this.http.get("/api/v1/measure-points")).data;
  }

  async addMeasurePoint(newMP: Omit<MeasurePoint, "measure_point_id">) {
    await this.http.post("/api/v1/measure-points", newMP);
  }

  async editMeasurePoint(newMP: Partial<Omit<MeasurePoint, "measure_point_id">>, mpId: number) {
    await this.http.put(`/api/v1/measure-points/${mpId}`, newMP);
  }

  async deleteMeasurePoint(mpId: number) {
    await this.http.delete(`/api/v1/measure-points/${mpId}`);
  }

  // SettingKey
  async getSettingKeys() {
    const response = await this.http.get("/api/v1/setting-keys");
    return response.data as ApplicationSettingKeys[];
  }

  async editSettingKey(sk: EditSettingKeyPayload, skId: Number) {
    await this.http.put(`/api/v1/setting-keys/${skId}`, sk);
  }

  // MeasureKey
  async getMeasureKeys(): Promise<ApplicationMeasureKeys[]> {
    return (await this.http.get("/api/v1/measure-keys")).data;
  }

  async editMeasureKey(measureKey: editMkPayload, measureKeyId: Number) {
    await this.http.put(`/api/v1/measure-keys/${measureKeyId}`, measureKey);
  }

  // AssetTemplates
  async getAssetAppliedTemplates(assetId: number): Promise<AppliedAssetTemplate[]> {
    return (await this.http.get(`/api/v1/assets/${assetId}/measure-points`)).data;
  }

  async applyAssetTemplate(assetId: number, templateId: number): Promise<AppliedAssetTemplate> {
    return (
      await this.http.post(`/api/v1/assets/${assetId}/measure-points`, {
        template_id: templateId,
      })
    ).data;
  }

  async deleteAssetTemplate(assetId: number, measurePointId: number) {
    await this.http.delete(`/api/v1/assets/${assetId}/measure-points/${measurePointId}`);
  }

  // Template
  async getAssetTemplates(assetTypeId: number): Promise<AssetTypeTemplate[]> {
    return (await this.http.get(`/api/v1/asset-types/${assetTypeId}/templates`)).data;
  }

  async addNewTemplate(assetTypeId: number, payload: AddTemplate): Promise<AssetTypeTemplate> {
    return (await this.http.post(`/api/v1/asset-types/${assetTypeId}/templates`, payload)).data;
  }

  async deleteTemplate(templateId: number) {
    await this.http.delete(`/api/v1/templates/${templateId}`);
  }

  async deleteTemplateDetail(templateId: number, templateDetailId: number) {
    await this.http.delete(`/api/v1/templates/${templateId}/details/${templateDetailId}`);
  }

  async updateTemplateValue(
    templateId: number,
    measureKeys: EditTemplateDetails | CopyTemplateDetails
  ): Promise<AssetTypeTemplate> {
    return (await this.http.put(`/api/v1/templates/${templateId}/details`, measureKeys)).data;
  }

  // Measure Key Value endpoints
  async editMeasureKeyValue(mkValueId: number, value: any) {
    await this.http.put(`/api/v1/asset-measure-keys/${mkValueId}`, { value });
  }

  async deleteMeasureKeyValue(mkValueId: number) {
    await this.http.delete(`/api/v1/asset-measure-keys/${mkValueId}`);
  }

  // Asset Type endpoints
  async getAssetTypes() {
    return (await this.http.get(`/api/v1/asset-types`)).data;
  }

  async cloneAssetType(assetTypeId: number) {
    return (await this.http.post(`/api/v1/asset-types/${assetTypeId}/cloning`)).data;
  }

  // GuidedSetup
  async createNewAssetGroup(payload: GuidedSetup): Promise<number> {
    return (await this.http.post("/api/v1/asset-group-setup", payload)).data.asset_group_id;
  }

  // Download installation File
  async installSetupFile(assetGroupId: number) {
    return (
      await this.http.get(`/api/v1/asset-groups/${assetGroupId}/installation`, {
        responseType: `arraybuffer`,
      })
    ).data;
  }

  // Locking
  async lockEntity(entity: LockableEntityInterface) {
    await this.http.post(
      `/api/v1/${getHierarchyEndpoint(entity)}/${getHierarchyId(entity)}/locking`
    );
  }

  async unlockEntity(entity: LockableEntityInterface) {
    await this.http.delete(
      `/api/v1/${getHierarchyEndpoint(entity)}/${getHierarchyId(entity)}/locking`
    );
  }

  // Units
  async getUnits(): Promise<Unit[]> {
    return (await this.http.get(`/api/v1/units`)).data;
  }
  async addUnit(unit: CreateUnit): Promise<Unit> {
    return (await this.http.post(`/api/v1/units`, unit)).data;
  }
  async changeUnit(assetId: number, measurePointId: number, unitId: number) {
    (
      await this.http.put(`/api/v1/assets/${assetId}/measure-points/${measurePointId}/unit`, {
        unit_id: unitId,
      })
    ).data;
  }

  // telematic devices: Logbox and Linux Client

  async getDevices(query: DeviceListQuery): Promise<Paginated<DeviceInformation>> {
    let queryArr = [];
    if (query.registered_only) {
      queryArr.push(`is_registered=${query.registered_only}`);
    }
    if (query.type) {
      queryArr.push(`type=${query.type}`);
    }
    if (query.filter_text) {
      queryArr.push(`filter_text=${query.filter_text}`);
    }
    if (query.page) {
      queryArr.push(`page=${query.page}`);
    }

    return (await this.http.get(`/api/v1/devices?${queryArr.join("&")}`)).data;
  }

  async assignDevice(deviceId: number, assetGroupId: number) {
    await this.http.post(`/api/v1/devices/${deviceId}/assignment`, {
      asset_group_id: assetGroupId,
    });
  }
  async getDeviceDump(deviceId: number) {
    return (
      await this.http.get(`/api/v1/devices/${deviceId}/latest-dump`, {
        responseType: `arraybuffer`,
      })
    ).data;
  }
  async createDevicesInBatch(devices: CreateDeviceBatch): Promise<DeviceBatchCreateResult[]> {
    return (await this.http.post(`/api/v1/devices/batch`, devices)).data;
  }
  async deleteDevice(deviceId: number) {
    await this.http.delete(`/api/v1/devices/${deviceId}`);
  }
  async detachDevice(deviceId: number) {
    await this.http.delete(`/api/v1/devices/${deviceId}/assignment`);
  }
}

export default new PrometheusAPI();
