import Vue from "vue";

import {
  SubAssetTemplate,
  AppliedSubAssetTemplate,
  AssetTypeTemplate,
  AppliedAssetTemplate,
  SingleAppliedAssetTemplate,
  SubAssetAssetTypeTemplate,
} from "@/constants/interfaces";
import { isTemplate, isSubAssetAppliedTemplate } from "@/constants/type_guards";

type MeasurePointWithMaybeUnit = AssetTypeTemplate | AppliedAssetTemplate;

type subAssetWithMaybeUnit = SubAssetTemplate | AppliedSubAssetTemplate;

export default Vue.extend({
  methods: {
    _getMeasurePointNameWithMaybeUnit(
      measurePoint: AssetTypeTemplate | SingleAppliedAssetTemplate
    ) {
      if (measurePoint.unit) {
        return `${measurePoint.measure_point_name} (${measurePoint.unit.symbol})`;
      }
      return measurePoint.measure_point_name;
    },

    getMeasurePointName(measurePoint: MeasurePointWithMaybeUnit) {
      if (isTemplate(measurePoint)) {
        return this._getMeasurePointNameWithMaybeUnit(measurePoint);
      }
      if (!isSubAssetAppliedTemplate(measurePoint)) {
        return this._getMeasurePointNameWithMaybeUnit(measurePoint);
      }

      return measurePoint.measure_point_name;
    },

    _getSuffixOrFullNameWithMaybeUnit(subAsset: subAssetWithMaybeUnit) {
      if ("unit" in subAsset) {
        return `${subAsset.name} (${subAsset.unit!.symbol})`;
      }
      return subAsset.name;
    },

    getSubAssetName(subAsset: subAssetWithMaybeUnit, template?: SubAssetAssetTypeTemplate) {
      let subAssetPrefix = "";
      if (template) {
        subAssetPrefix = template.sub_asset_name;
      }
      return `${subAssetPrefix} ${this._getSuffixOrFullNameWithMaybeUnit(subAsset)}`;
    },
  },
});
