







































import Vue from "vue";

import PrometheusAPI from "@/helpers/prometheus_api";
import {
  TemplateInterface,
  AppliedTemplateDetails,
  TemplateDetail,
  MeasureKeyValue,
} from "@/constants/interfaces";
import { notificationType, notificationGroup } from "@/constants/constants";
import { EditTemplateDetails } from "@/constants/api_interfaces";
import { parseStringToValue } from "@/helpers/global_helpers";

import EditCell from "@/components/common/EditCell.vue";

export default Vue.extend({
  name: "TemplateDetail",

  components: { EditCell },

  props: {
    appTemplate: {
      type: Object as () => TemplateInterface | AppliedTemplateDetails,
      required: true,
    },
    subAssetSuffix: {
      type: String,
      required: false,
    },
    templateOrMeasurePointId: {
      type: Number,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    canUserEdit: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showType: "measure_keys" in this.appTemplate,
    };
  },

  computed: {
    details: function() {
      if ("template_details" in this.appTemplate) {
        return this.appTemplate.template_details;
      } else {
        return this.appTemplate.measure_keys;
      }
    },
  },

  methods: {
    async updateTempDetail(
      appTemp: TemplateInterface,
      detail: TemplateDetail,
      value: string | number
    ) {
      let templatePayload: EditTemplateDetails;
      if (this.subAssetSuffix) {
        templatePayload = [
          { value, measure_key_id: detail.measure_key_id, sub_asset_suffix: this.subAssetSuffix },
        ];
      } else {
        templatePayload = [{ value, measure_key_id: detail.measure_key_id }];
      }

      try {
        await PrometheusAPI.updateTemplateValue(this.templateOrMeasurePointId, templatePayload);
      } catch (error) {
        console.warn(`Error editing measure key value: ${error}`);
        return;
      }

      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: `Template value updated`,
      });

      let changedIdx = appTemp.template_details.findIndex(
        det => det.measure_key_id === detail.measure_key_id
      );
      let changedDetail = appTemp.template_details[changedIdx];
      changedDetail!.value = value;
      appTemp.template_details.splice(changedIdx, 1, changedDetail);

      this.$emit("updatedetails", {
        ...this.appTemplate,
        template_details: appTemp.template_details,
      });
    },

    async updateMkValue(
      appTemp: AppliedTemplateDetails,
      detail: MeasureKeyValue,
      value: string | number
    ) {
      try {
        await PrometheusAPI.editMeasureKeyValue(detail.measure_key_value_id, value);
      } catch (error) {
        console.warn(`Error editing measure key value: ${error}`);
        return;
      }

      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: `${this.$t("measure_key")} value updated`,
      });

      let changedIdx = appTemp.measure_keys.findIndex(
        det => det.measure_key_id === detail.measure_key_id
      );
      let changedDetail = appTemp.measure_keys[changedIdx];
      changedDetail!.value = value;
      appTemp.measure_keys.splice(changedIdx, 1, changedDetail);

      this.$emit("updatedetails", {
        ...this.appTemplate,
        measure_keys: appTemp.measure_keys,
      });
    },

    async updateMk(value: string, det: TemplateDetail | MeasureKeyValue) {
      let parsedVal: string | number;
      try {
        parsedVal = parseStringToValue(value, det.type_checker);
      } catch (error) {
        this.$notify({
          group: notificationGroup.general,
          type: notificationType.error,
          title: error,
        });
        return;
      }

      // what is this?
      if ("measure_key_value_id" in det) {
        return this.updateMkValue(this.appTemplate as AppliedTemplateDetails, det, parsedVal);
      } else {
        return this.updateTempDetail(this.appTemplate as TemplateInterface, det, parsedVal);
      }
    },

    async delTempDetail(appTemp: TemplateInterface, detail: TemplateDetail) {
      await PrometheusAPI.deleteTemplateDetail(
        this.templateOrMeasurePointId,
        detail.template_detail_id
      );

      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: `Deleted ${this.$t("measure_key")} from ${this.$t("template")}`,
      });
      this.$emit("updatedetails", {
        ...appTemp,
        template_details: appTemp.template_details.filter(
          det => det.template_detail_id !== detail.template_detail_id
        ),
      });
    },

    async delMkValue(appTemp: AppliedTemplateDetails, detail: MeasureKeyValue) {
      await PrometheusAPI.deleteMeasureKeyValue(detail.measure_key_value_id);

      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: `Deleted ${this.$t("measure_key")} from ${this.$t("asset")}`,
      });
      this.$emit("updatedetails", {
        ...appTemp,
        measure_keys: appTemp.measure_keys.filter(
          det => det.measure_key_value_id !== detail.measure_key_value_id
        ),
      });
    },

    async deleteMk(det: TemplateDetail | MeasureKeyValue) {
      const confirmMessage = `Do you really want to delete the ${this.$t("measure_key")}: ${
        det.measure_key_name
      }?`;

      if (!(await this.$confirm(confirmMessage))) return;

      if ("measure_key_value_id" in det) {
        return this.delMkValue(this.appTemplate as AppliedTemplateDetails, det);
      } else {
        return this.delTempDetail(this.appTemplate as TemplateInterface, det);
      }
    },
  },
});
