











import Vue from "vue";

import { getHierarchyName } from "@/helpers/schema_helpers";
import { hierarchyLevel, PageRoutes } from "@/constants/constants";
import { ActionTypes } from "@/stores/action_types";
import { RootState } from "@/stores/types";

export default Vue.extend({
  data() {
    return {
      hierarchyLevels: [
        {
          level: hierarchyLevel.organization,
          text: getHierarchyName(hierarchyLevel.organization),
        },
        {
          level: hierarchyLevel.fleet,
          text: getHierarchyName(hierarchyLevel.fleet),
        },
        {
          level: hierarchyLevel.productGroup,
          text: getHierarchyName(hierarchyLevel.productGroup),
        },
        {
          level: hierarchyLevel.assetGroup,
          text: getHierarchyName(hierarchyLevel.assetGroup),
        },
        {
          level: hierarchyLevel.assetType,
          text: getHierarchyName(hierarchyLevel.assetType),
        },
        {
          level: hierarchyLevel.asset,
          text: getHierarchyName(hierarchyLevel.asset),
        },
      ] as { level: hierarchyLevel; text: string }[],
    };
  },

  methods: {
    selectHierarchy(selectedHierarchy: hierarchyLevel) {
      this.$store.dispatch(ActionTypes.setNavHierarchy, selectedHierarchy);
      this.$store.dispatch(ActionTypes.setEntityFilter, "");
    },

    isActiveClass(hierarchy: hierarchyLevel) {
      const isEntityRoute = [
        PageRoutes.entitiesEdit,
        PageRoutes.configEdit,
        PageRoutes.templates,
        PageRoutes.assetTemplates,
      ].includes(this.$route.path as PageRoutes);

      const isActiveHierarchy = (this.$store.state as RootState).navHierarchy === hierarchy;

      return isEntityRoute && isActiveHierarchy ? "active" : "";
    },
  },
});
