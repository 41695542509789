import {
  ConfigRowInterface,
  ConfigRowValues,
  ApplicationSettingValues,
} from "@/constants/interfaces";

export function getConfigTableSettings(settings: ApplicationSettingValues[]): ConfigRowInterface[] {
  return settings.flatMap(appSettings => parseAppSettings(appSettings));
}

function parseAppSettings(appSettings: ApplicationSettingValues) {
  const settingsArray = [
    {
      type: "application",
      level: 1,
      key: appSettings.application_name,
      app: {
        id: appSettings.application_id,
        name: appSettings.application_name,
      },
    } as ConfigRowInterface,
  ];

  Object.entries(appSettings.setting_keys).forEach(([settingKey, setting]) => {
    const configRow = {
      type: "value",
      level: 1,
      key: settingKey,
      setting_key_id: setting.setting_key_id,
      description: setting.description,
      values: { ...setting.values },
      app: {
        id: appSettings.application_id,
        name: appSettings.application_name,
      },
    } as ConfigRowValues;
    configRow.values.result = setting.result_value;
    settingsArray.push(configRow);
  });

  return settingsArray;
}
