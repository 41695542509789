

































































import Vue from "vue";

import PrometheusAPI from "@/helpers/prometheus_api";
import { hierarchyLevel, notificationType, notificationGroup } from "@/constants/constants";
import { FleetInterface, OrganizationInterface } from "@/constants/interfaces";
import { getParentsForSelect } from "@/components/entities_edit/helper_functions";
import { CreateFleet, EditFleet } from "@/constants/api_interfaces";

import ModalWindow from "@/components/common/ModalWindow.vue";

export default Vue.extend({
  name: "FleetModal",
  components: {
    ModalWindow,
  },
  props: {
    entityItem: {
      type: Object as () => FleetInterface,
    },
    isReadonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      parentList: [] as OrganizationInterface[],
      chosenName: this.entityItem ? this.entityItem.name : null,
      displayName: this.entityItem ? this.entityItem.display_name : null,
      chosenParent: this.entityItem ? this.entityItem.organization_id : -1,
    };
  },

  computed: {
    modalTitle(): string {
      if (this.isReadonly) return this.$t("fleet") as string;
      const mode = !this.entityItem ? "Create new: " : "Edit: ";
      return mode + this.$t("fleet");
    },
  },

  methods: {
    onCancel() {
      this.$emit("modal-done");
    },

    onConfirm() {
      if (!this.entityItem) return this.saveEntity();
      else return this.editEntity();
    },

    async saveEntity() {
      let postBody: CreateFleet = {
        name: this.chosenName as string,
        display_name: this.displayName as string,
        organization_id: this.chosenParent as number,
      };

      await PrometheusAPI.createFleet(postBody);
      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: "Saving success",
      });
      this.$emit("entity-saved");
    },

    async editEntity() {
      let putBody: EditFleet = {};
      if (this.chosenName && this.chosenName !== this.entityItem.name) {
        putBody.name = this.chosenName;
      }
      if (this.displayName && this.displayName !== this.entityItem.display_name) {
        putBody.display_name = this.displayName;
      }
      if (this.chosenParent !== this.entityItem.organization_id) {
        putBody.organization_id = this.chosenParent;
      }

      await PrometheusAPI.editFleet(this.entityItem.fleet_id, putBody);
      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: "Editing succeeded",
      });
      this.$emit("entity-saved");
    },
  },

  async mounted() {
    this.parentList = (await getParentsForSelect(
      this,
      hierarchyLevel.organization,
      this.entityItem
    )) as OrganizationInterface[];
  },
});
