






























import Vue from "vue";

import {
  AssetTypeTemplate,
  AssetTypeInterface,
  ApplicationMeasureKeys,
  SubAssetTemplate,
} from "@/constants/interfaces";
import PrometheusAPI from "@/helpers/prometheus_api";
import { RootState, AssetTypeEditState } from "@/stores/types";
import { AssetTypeEditActions } from "@/stores/action_types";

import TemplateModalAdd from "@/components/templates/template_modal/TemplateModalAdd.vue";
import TemplateModalEdit from "@/components/templates/template_modal/TemplateModalEdit.vue";
import TemplateModalCopy from "@/components/templates/template_modal/TemplateModalCopy.vue";

export default Vue.extend({
  name: "TemplateModal",

  components: {
    TemplateModalAdd,
    TemplateModalEdit,
    TemplateModalCopy,
  },

  props: {
    changeMode: {
      type: String as () => "add" | "edit" | "copy",
      required: true,
    },
    existingTemplates: {
      type: Array as () => AssetTypeTemplate[],
      required: false,
    },
    editTemplate: {
      type: Object as () => AssetTypeTemplate,
      required: false,
    },
    editSubAsset: {
      type: Object as () => SubAssetTemplate,
      required: false,
    },
  },

  data() {
    return {
      isLoading: true,
      state: this.$store.state.assetTypeEdit as AssetTypeEditState,
      assetType: (this.$store.state as RootState).selectedEntity as AssetTypeInterface,
      appMkList: [] as ApplicationMeasureKeys[],
    };
  },

  methods: {
    cleanupAndForwardEvent(eventType: string) {
      this.$store.dispatch(AssetTypeEditActions.clearTemplateEdit);
      this.$emit(eventType);
    },
  },

  mounted() {
    this.$store.dispatch(AssetTypeEditActions.setChangeMode, this.changeMode);
    if (this.editTemplate) {
      this.$store.dispatch(AssetTypeEditActions.setTemplateData, this.editTemplate);
    }
    if (this.editSubAsset) {
      this.$store.dispatch(AssetTypeEditActions.setSubAssetSuffix, this.editSubAsset.name);
    }
    if (this.existingTemplates) {
      this.$store.dispatch(AssetTypeEditActions.setExistingTemplates, this.existingTemplates);
    }
  },

  async created() {
    this.appMkList = await PrometheusAPI.getMeasureKeys();
    if (!this.state.unitList) {
      await this.$store.dispatch(AssetTypeEditActions.getUnits);
    }
    this.isLoading = false;
  },
});
