




































import mixins from "vue-typed-mixins";

import PrometheusAPI from "@/helpers/prometheus_api";
import { EntityInterface, AssetGroupInterface, AssetTypeInterface } from "@/constants/interfaces";
import { getHierarchyLevel, getHierarchyId, getHierarchyName } from "@/helpers/schema_helpers";
import { hierarchyLevel, notificationType, notificationGroup } from "@/constants/constants";

import CheckRights from "@/components/entities_edit/mixins/CheckRights";
import IconBase from "@/components/common/IconBase.vue";
import VerticalMoreIcon from "@/components/common/icons/VerticalMoreIcon.vue";

export default mixins(CheckRights).extend({
  name: "EntityActions",

  props: {
    entity: {
      type: Object as () => EntityInterface,
      required: true,
    },
  },

  components: {
    VerticalMoreIcon,
    IconBase,
  },

  computed: {
    canEdit(): boolean {
      return this.canEditEntity(this.entity);
    },
    hasCloneBtn(): boolean {
      return getHierarchyLevel(this.entity) === hierarchyLevel.assetType;
    },
    hasDownloadBtn(): boolean {
      return getHierarchyLevel(this.entity) === hierarchyLevel.assetGroup;
    },
    canDownloadSetupFile(): boolean {
      return !(this.entity as AssetGroupInterface).locks;
    },
    hasEditRights: function() {
      return this.$store.getters["entity/hasEntityEditRights"];
    },
  },

  methods: {
    async cloneAssetType() {
      const assetType = this.entity as AssetTypeInterface;
      await PrometheusAPI.cloneAssetType(assetType.asset_type_id);
      this.$emit("reloadentities");

      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: "Success",
        text: `The ${getHierarchyName(hierarchyLevel.assetType)} has been clone`,
      });
    },
    async deleteEntity() {
      const level = getHierarchyLevel(this.entity);
      const hierarchyName = getHierarchyName(level);

      const confirmMsg = `Do you really want to delete the ${hierarchyName} ${this.entity.name}?`;
      if (!(await this.$confirm(confirmMsg))) return;

      await PrometheusAPI.deleteEntity(getHierarchyId(this.entity), level);
      this.$emit("reloadentities");

      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: "Success",
        text: `The ${getHierarchyName(level)} has been deleted`,
      });
    },
  },
});
