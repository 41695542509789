






























import Vue from "vue";

import { MeasurePoint } from "@/constants/interfaces";
import PrometheusAPI from "@/helpers/prometheus_api";
import { notificationType, notificationGroup } from "@/constants/constants";

import EditCell from "@/components/common/EditCell.vue";

export default Vue.extend({
  name: "MeasurePointsTable",

  components: { EditCell },

  props: {
    mpList: Array as () => MeasurePoint[],
  },

  methods: {
    async deleteMp(mpId: number) {
      if (!(await this.$confirm(`Do you really want to delete the measure point?`))) return;

      await PrometheusAPI.deleteMeasurePoint(mpId);

      this.$emit("updatelist");
      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: "Success",
        text: `Deleted ${this.$t("measure_point")} successfully`,
      });
    },

    async onSave(key: "name" | "readable_name", value: string, oldMp: MeasurePoint) {
      await PrometheusAPI.editMeasurePoint({ [key]: value }, oldMp.measure_point_id);

      this.$emit("updatelist");
      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: "Success",
        text: `Edited ${this.$t("measure_point")} successfully`,
      });
    },
  },
});
