




















import Vue from "vue";

import { EntityState } from "@/stores/types";
import { ActionTypes } from "@/stores/action_types";

import CreateEditModal from "@/components/entities_edit/create_edit_modal/CreateEditModal.vue";
import TextFilter from "@/components/common/TextFilter.vue";

export default Vue.extend({
  name: "EntityMenu",

  components: {
    TextFilter,
    CreateEditModal,
  },

  data() {
    return {
      state: this.$store.state.entity as EntityState,
      isModalVisible: false,
    };
  },

  computed: {
    filterValue() {
      return this.$store.state.entity.filter;
    },
    navHierarchy() {
      return this.$store.state.navHierarchy;
    },

    hasEditRights: function() {
      return this.$store.getters["entity/hasEntityEditRights"];
    },
  },

  methods: {
    updateFilter(value: string) {
      this.$store.dispatch(ActionTypes.setEntityFilter, value);
    },
    addNewEntity() {
      this.isModalVisible = true;
    },
    hideModal(success: boolean) {
      this.isModalVisible = false;

      if (success) {
        this.$emit("reloadentities");
      }
    },
  },
});
