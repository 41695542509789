// Actual mutation functions

import { MutationTree } from "vuex";
import { LoginState } from "@/stores/types";
import { UserClaims } from "@/constants/interfaces";

// This includes all mutation functions
export const mutations: MutationTree<LoginState> = {
  setLoginStatus(state: LoginState, userClaims: UserClaims) {
    state.userClaims = userClaims;
  },
  logout(state: LoginState) {
    state.userClaims = null;
  },
};
