
































































































import Vue from "vue";

import PrometheusAPI from "@/helpers/prometheus_api";
import { AppliedAssetTemplate } from "@/constants/interfaces";
import { notificationGroup, notificationType } from "@/constants/constants";
import { isSubAssetAppliedTemplate } from "@/constants/type_guards";
import { AssetTypeEditState } from "@/stores/types";
import { AssetTypeEditActions } from "@/stores/action_types";

import CollapseList from "@/mixins/CollapseList";
import TableHeaderNames from "@/components/templates/mixins/TableHeaderNames";
import SubAssetCollapseList from "@/components/templates/mixins/SubAssetCollapseList";
import TemplateDetailsUpdate from "@/components/templates/mixins/TemplateDetailsUpdate";

import TemplateDetail from "@/components/templates/TemplateDetail.vue";
import AppliedTemplateTableUnitEdit from "@/components/templates/AppliedTemplateTableUnitEdit.vue";

export default Vue.extend({
  name: "AppliedTemplateTable",

  mixins: [TableHeaderNames, CollapseList, SubAssetCollapseList, TemplateDetailsUpdate],

  components: {
    TemplateDetail,
    AppliedTemplateTableUnitEdit,
  },

  props: {
    templates: {
      type: Array as () => AppliedAssetTemplate[],
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    assetId: {
      type: Number,
      required: true,
    },
  },

  methods: {
    isSubAssetTemplate(applAssetTemplate: AppliedAssetTemplate) {
      return isSubAssetAppliedTemplate(applAssetTemplate);
    },

    async removeTemplate(applAssetTemplate: AppliedAssetTemplate) {
      try {
        await PrometheusAPI.deleteAssetTemplate(this.assetId, applAssetTemplate.measure_point_id);
      } catch (_) {
        return;
      }

      this.$notify({
        title: `Successfully removed: ${applAssetTemplate.measure_point_name}`,
        group: notificationGroup.general,
        type: notificationType.success,
      });
      this.$emit("remove-template", applAssetTemplate);
    },
  },

  async created() {
    if (!(this.$store.state.assetTypeEdit as AssetTypeEditState).unitList) {
      await this.$store.dispatch(AssetTypeEditActions.getUnits);
    }
  },
});
