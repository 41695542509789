import Vue from "vue";
import Router from "vue-router";
import Notifications from "vue-notification";
import VuejsDialog from "vuejs-dialog";
import "vuejs-dialog/dist/vuejs-dialog.min.css";

import App from "@/App.vue";
import router from "@/router";
import store from "@/stores";
import { i18n } from "@/plugins/i18n";
import VuejsDialogWrapper from "@/plugins/dialog";

Vue.config.productionTip = false;
Vue.use(Notifications);
Vue.use(VuejsDialog);
Vue.use(VuejsDialogWrapper);
Vue.use(Router);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount("#app");
