import { MutationTree } from "vuex";
import { AssetTypeEditState } from "@/stores/types";
import { AssetTypeTemplate, MeasurePoint, Unit } from "@/constants/interfaces";
import { DEFAULT_UNIT } from "@/constants/constants";

export const mutations: MutationTree<AssetTypeEditState> = {
  setTemplateData(state: AssetTypeEditState, template: AssetTypeTemplate) {
    state.compositeMeasurePointName =
      template.measure_point_name + " - " + template.measure_point_readable_name;

    if (template.sub_asset_name) {
      state.subAssetName = template.sub_asset_name;
    }

    if (template.unit) {
      state.compositeUnitName = `${template.unit.name} (${template.unit.symbol})`;
    } else {
      state.compositeUnitName = DEFAULT_UNIT.name;
    }
  },
  setChangeMode(state: AssetTypeEditState, mode: string) {
    state.changeMode = mode;
  },
  setExistingTemplates(state: AssetTypeEditState, templates: AssetTypeTemplate[]) {
    state.existingTemplates = templates;
  },
  setMeasurePoints(state: AssetTypeEditState, mps: MeasurePoint[]) {
    state.measurePointList = mps;
  },
  setUnits(state: AssetTypeEditState, units: Unit[]) {
    state.unitList = units;
  },
  addUnitToUnitList(state: AssetTypeEditState, unit: Unit) {
    state.unitList!.push(unit);
  },

  setMeasurePointName(state: AssetTypeEditState, userInput: string) {
    state.measurePointName = userInput;
  },
  setSubAssetName(state: AssetTypeEditState, name: string) {
    state.subAssetName = name;
  },
  setSubAssetSuffix(state: AssetTypeEditState, suffix: string) {
    state.subAssetSuffix = suffix;
  },
  setUnit(state: AssetTypeEditState, unit: string) {
    state.unitName = unit;
  },
  clearTemplateEdit(state: AssetTypeEditState) {
    state.compositeMeasurePointName = null;
    state.compositeUnitName = null;
    state.unitName = null;
    state.measurePointName = null;
    state.subAssetName = null;
    state.subAssetSuffix = null;
  },
};
