








import Vue from "vue";

import PrometheusAPI from "@/helpers/prometheus_api";
import { ApplicationSettingKeys } from "@/constants/interfaces";

import SettingKeysHeader from "@/components/setting_keys/SettingKeysHeader.vue";
import SettingKeysTable from "@/components/setting_keys/SettingKeysTable.vue";
import { strSearchItem } from "@/helpers/global_helpers";

export default Vue.extend({
  name: "SettingKeys",

  components: {
    SettingKeysHeader,
    SettingKeysTable,
  },

  data() {
    return {
      settingKeysPerAppList: [] as ApplicationSettingKeys[],
      listFilter: "",
    };
  },

  computed: {
    filteredSkPerAppList: function() {
      const filter = this.listFilter;
      if (!filter) return this.settingKeysPerAppList;
      else {
        return this.settingKeysPerAppList.map(app => {
          return {
            ...app,
            setting_keys: app.setting_keys.filter(item => {
              return strSearchItem(filter, item);
            }),
          };
        });
      }
    },
  },

  methods: {
    updateFilter(value: string) {
      this.listFilter = value;
    },

    async updateAppSkList() {
      this.settingKeysPerAppList = await PrometheusAPI.getSettingKeys();
    },
  },

  async mounted() {
    this.settingKeysPerAppList = await PrometheusAPI.getSettingKeys();
  },
});
