













import Vue from "vue";
import { detect } from "detect-browser";

import { notificationGroup, notificationType } from "@/constants/constants";

import SideBar from "@/components/common/side_bar/SideBar.vue";
import AppHeader from "@/components/common/AppHeader.vue";

export default Vue.extend({
  name: "App",

  components: {
    SideBar,
    AppHeader,
  },

  data() {
    return {
      notificationGroup,
    };
  },

  /** We check via the navigator.userAgent for browser compatability
   * We support Firefox (>63) and by extension chrome (approx >69)
   */
  mounted() {
    const browser = detect();

    if (browser) {
      switch (browser.name) {
        case "firefox":
          if (parseInt(browser.version.split(".")[0]) >= 63) return;
          break;
        case "chrome":
          if (parseInt(browser.version.split(".")[0]) >= 69) return;
          break;
      }

      this.$notify({
        title: "Compatability warning",
        group: notificationGroup.general,
        type: notificationType.warn,
        text: "For an optimal experience use an up to date Firefox (>63) or Chrome (>69) version",
        duration: 10000,
      });
    }
  },
});
