










import Vue from "vue";

import { LockableEntityInterface } from "@/constants/interfaces";
import { hierarchyLevel } from "@/constants/constants";
import PrometheusAPI from "@/helpers/prometheus_api";
import { isCurrentUser } from "@/helpers/auth_services";
import { getHierarchyLevel } from "@/helpers/schema_helpers";

import IconBase from "@/components/common/IconBase.vue";
import Lock from "@/components/common/icons/Lock.vue";
import Unlock from "@/components/common/icons/Unlock.vue";

export default Vue.extend({
  name: "CheckOut",

  components: {
    IconBase,
    Lock,
    Unlock,
  },

  props: {
    entity: Object as () => LockableEntityInterface,
  },

  computed: {
    isBtnDisabled: function() {
      if (this.$store.state.navHierarchy === hierarchyLevel.asset) return true;

      if (
        this.entity.locks &&
        !this.entity.locks.some(lock => {
          return lock.is_root && isCurrentUser(lock.user_name, this);
        })
      ) {
        return true;
      }

      return false;
    },
    lockUsers() {
      if (!this.entity.locks) return "";
      else return [...new Set(this.entity.locks.map(lock => lock.user_name))].join(", ");
    },
  },

  methods: {
    toggleLock() {
      return this.entity.locks ? this.unlock() : this.lock();
    },

    async lock() {
      const confirmation = await this.$confirm("Do you want to check out the selected object?");
      if (confirmation) {
        await PrometheusAPI.lockEntity(this.entity);
        this.$emit("lockupdate");
      }
    },

    async unlock() {
      const level = getHierarchyLevel(this.entity);

      let extraMessage = `<br> <hr> This will release all app configurations for `;

      if (level === hierarchyLevel.assetGroup) {
        extraMessage += `this ${this.$t("asset_group")}`;
      } else {
        extraMessage += `${this.$t("asset_groups")} below <b>${this.entity.name}</b>
         ${this.$t(level)}`;
      }

      const confirmation = await this.$confirm(
        `Do you want to check in the selected ${this.$t("entity")}? ${extraMessage}`,
        { html: true }
      );

      if (confirmation) {
        await PrometheusAPI.unlockEntity(this.entity);
        this.$emit("lockupdate");
      }
    },
  },
});
