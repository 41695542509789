





















import Vue from "vue";

import { AssetTypeTemplate } from "@/constants/interfaces";

import TemplateModal from "@/components/templates/template_modal/TemplateModal.vue";
import TextFilter from "@/components/common/TextFilter.vue";

export default Vue.extend({
  name: "AssetTypeTemplatesHeader",

  props: {
    existingTemplates: { type: Array as () => AssetTypeTemplate[], required: true },
    canUserEdit: { type: Boolean, required: true },
  },

  components: {
    TemplateModal,
    TextFilter,
  },

  data() {
    return {
      isAddModalVisible: false,
    };
  },

  methods: {
    showModal() {
      this.isAddModalVisible = true;
    },
    hideModal() {
      this.isAddModalVisible = false;
    },
    savedTemplate() {
      this.hideModal();
      this.$emit("add-template");
    },
  },
});
