export enum ActionTypes {
  getMeasurePoints = "assetTypeEdit/getMeasurePoints",
  getMeasureKeys = "assetTypeEdit/getMeasureKeys",
  getUnits = "assetTypeEdit/getUnits",
  setExistingTemplates = "assetTypeEdit/setExistingTemplates",
  setTemplateData = "assetTypeEdit/setTemplateData",
  clearTemplateEdit = "assetTypeEdit/clearTemplateEdit",
  setChangeMode = "assetTypeEdit/setChangeMode",
  setMeasurePointName = "assetTypeEdit/setMeasurePointName",
  setSubAssetName = "assetTypeEdit/setSubAssetName",
  setSubAssetSuffix = "assetTypeEdit/setSubAssetSuffix",
  setUnit = "assetTypeEdit/setUnit",
  addUnitToUnitList = "assetTypeEdit/addUnitToUnitList",
}
