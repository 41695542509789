import { ActionTree, Commit } from "vuex";

import router from "@/router";
import { EntityInterface } from "@/constants/interfaces";
import { RootState } from "./types";
import { hierarchyLevel, PageRoutes } from "@/constants/constants";

type context = { commit: Commit; state: RootState; getters: any; rootGetters: any };

export const actions: ActionTree<RootState, RootState> = {
  initState({ commit }: context) {
    commit("initState");
  },

  setSelectedEntity({ state, commit, rootGetters }: context, entity: EntityInterface) {
    commit("setSelectedEntity", {
      selectedEntity: entity,
      loggedInUser: rootGetters["login/user"].userName,
    });
  },

  toggleConfigLevel({ commit }: context, configPath: string) {
    commit("toggleConfigLevelVisibility", configPath);
  },

  setNavHierarchy({ commit }: context, hierarchy: hierarchyLevel) {
    if (router.currentRoute.path !== PageRoutes.entitiesEdit) {
      router.push(PageRoutes.entitiesEdit);
    }
    commit("setNavHierarchy", hierarchy);
  },
};
