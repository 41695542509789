




























































import Vue from "vue";

import { ParentsGuidedSetup } from "@/constants/interfaces";
import PrometheusAPI from "@/helpers/prometheus_api";
import { GetNotificationObj } from "@/constants/notifications";
import { downloadInstallationFile } from "@/components/guided_setup/download";

import ParentSetup from "@/components/guided_setup/ParentSetup.vue";
import AssetSetup from "@/components/guided_setup/AssetSetup.vue";
import LoadingSpinner from "@/components/common/LoadingSpinner.vue";
import { assetGuidedSetup } from "../constants/api_interfaces";

export default Vue.extend({
  name: "GuidedSetup",

  components: {
    ParentSetup,
    AssetSetup,
    LoadingSpinner,
  },

  data() {
    return {
      assetGroupName: "",
      assetGroupDisplayName: "",
      assetGroupSerial: "",
      newAssetGroupId: null as null | number,
      loading: false,
    };
  },

  methods: {
    async createNewAssetGroup() {
      const parentSetup = (this
        .$refs as any).parentSetup.getParentSetupData() as ParentsGuidedSetup;
      const assetSetupData = (this.$refs as any).assetSetup.getAssetSetupData() as assetGuidedSetup;

      this.newAssetGroupId = await PrometheusAPI.createNewAssetGroup({
        asset_group_name: this.assetGroupName,
        display_name: this.assetGroupDisplayName,
        asset_group_serial: this.assetGroupSerial,
        organization: parentSetup.organization,
        fleet: parentSetup.fleet,
        product_group: parentSetup.productGroup,
        assets: assetSetupData,
      });

      this.$notify(
        GetNotificationObj(
          true,
          "Success",
          `New asset group ${this.assetGroupName} 
        DISPLAY NAME ${this.assetGroupDisplayName} created`
        )
      );
    },

    async downloadSetupFile() {
      this.loading = true;
      const result = await downloadInstallationFile(
        this.newAssetGroupId!,
        this.assetGroupName,
        this
      );
      this.loading = false;

      if (result) {
        this.newAssetGroupId = null;
      }
    },
  },
});
