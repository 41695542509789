








import Vue from "vue";

import PrometheusAPI from "@/helpers/prometheus_api";
import { ApplicationMeasureKeys } from "@/constants/interfaces";

import MeasureKeysHeader from "@/components/measure_keys/MeasureKeysHeader.vue";
import MeasureKeysTable from "@/components/measure_keys/MeasureKeysTable.vue";
import { strSearchItem } from "@/helpers/global_helpers";

export default Vue.extend({
  name: "MeasureKeys",

  components: {
    MeasureKeysHeader,
    MeasureKeysTable,
  },

  data() {
    return {
      appMeasureKeyList: [] as ApplicationMeasureKeys[],
      listFilter: "",
    };
  },

  computed: {
    filteredAppMkList: function() {
      const filter = this.listFilter;
      if (!filter) return this.appMeasureKeyList;
      else {
        return this.appMeasureKeyList.map(app => {
          return {
            ...app,
            measure_keys: app.measure_keys.filter(item => {
              return strSearchItem(filter, item);
            }),
          };
        });
      }
    },
  },

  methods: {
    updateFilter(value: string) {
      this.listFilter = value;
    },

    async loadMeasureKeys() {
      this.appMeasureKeyList = await PrometheusAPI.getMeasureKeys();
    },

    _find_mk(mkId: number) {
      let appIndex: null | number = null;
      let mkIndex: null | number = null;

      this.appMeasureKeyList.some((app, appIdx) => {
        return app.measure_keys.some((mk, mkIdx) => {
          if (mk.measure_key_id === mkId) {
            appIndex = appIdx;
            mkIndex = mkIdx;
            return true;
          } else return false;
        });
      });

      if (appIndex !== null && mkIndex !== null) return { appIndex, mkIndex };
      else throw new Error(`Did not find measure key id ${mkId} even though it should exist`);
    },

    async mkEdited() {
      await this.loadMeasureKeys();
    },
  },

  mounted: async function() {
    await this.loadMeasureKeys();
  },
});
