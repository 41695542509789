import Vue from "vue";

import CollapseIcon from "@/components/common/CollapseIcon.vue";
import { SubAssetTemplate, AppliedSubAssetTemplate } from "@/constants/interfaces";
import { isTemplateSubAsset } from "@/constants/type_guards";

export default Vue.extend({
  components: {
    CollapseIcon,
  },

  data() {
    return {
      uncollapsedSubAssetList: {} as { [mpId: number]: string[] },
    };
  },

  methods: {
    isSubAssetAppEmpty(subAsset: SubAssetTemplate | AppliedSubAssetTemplate) {
      if (isTemplateSubAsset(subAsset)) {
        return subAsset.details.every(det => det.template_details.length === 0);
      }
      return subAsset.details.every(det => det.measure_keys.length === 0);
    },

    toggleCollapsedSubAsset(mpId: number, subAssetSuffix: string) {
      if (!this.uncollapsedSubAssetList[mpId]) {
        this.$set(this.uncollapsedSubAssetList, mpId, []);
      }

      const pos = this.uncollapsedSubAssetList[mpId].indexOf(subAssetSuffix);
      if (pos !== -1) {
        this.uncollapsedSubAssetList[mpId].splice(pos, 1);
      } else {
        this.uncollapsedSubAssetList[mpId].push(subAssetSuffix);
      }

      // to get vue to update on the nested state change
      this.$set(this.uncollapsedSubAssetList, mpId, this.uncollapsedSubAssetList[mpId]);
    },

    isCollapsedSubAsset(mpId: number, subAssetSuffix: string) {
      return !(
        this.uncollapsedSubAssetList[mpId] &&
        this.uncollapsedSubAssetList[mpId].includes(subAssetSuffix)
      );
    },
  },
});
