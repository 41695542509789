








































import Vue from "vue";

import PrometheusAPI from "@/helpers/prometheus_api";
import { notificationType, notificationGroup } from "@/constants/constants";

import ModalWindow from "@/components/common/ModalWindow.vue";

export default Vue.extend({
  name: "MeasurePointActions",

  components: {
    ModalWindow,
  },

  data() {
    return {
      newMPName: "",
      newMPReadableName: "",

      isAddModalVisible: false,
    };
  },

  methods: {
    addMP() {
      this.isAddModalVisible = true;
    },

    onCancel() {
      this.newMPName = "";
      this.newMPReadableName = "";
      this.isAddModalVisible = false;
    },

    validateUserInput() {
      if (!this.newMPName) return false;

      return true;
    },

    async onAddSave() {
      if (this.validateUserInput()) {
        await PrometheusAPI.addMeasurePoint({
          name: this.newMPName,
          readable_name: this.newMPReadableName || null,
        });

        this.onCancel();
        this.$emit("savemp");
        this.$notify({
          group: notificationGroup.general,
          type: notificationType.success,
          title: "Success",
          text: `Saved ${this.$t("measure_point")} successfully`,
        });
      } else {
        this.$notify({
          group: notificationGroup.general,
          type: notificationType.error,
          title: "'Name' cannot be empty!",
        });
      }
    },
  },
});
