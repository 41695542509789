












import Vue from "vue";

import { ConfigRowApp } from "@/constants/interfaces";

export default Vue.extend({
  name: "TitleRow",

  props: {
    rowData: Object as () => ConfigRowApp,
    hiddenRows: Array as () => String[],
    columnNumber: Number,
  },

  computed: {
    isToggled: function() {
      return this.hiddenRows.includes(this.rowData.key);
    },
  },

  methods: {
    toggleLevel() {
      this.$emit("toggle-row", this.rowData);
    },
  },
});
