import Vue from "vue";

import CollapseIcon from "@/components/common/CollapseIcon.vue";

export default Vue.extend({
  components: {
    CollapseIcon,
  },

  data() {
    return {
      uncollapsedList: [] as number[],
    };
  },

  methods: {
    toggleCollapsed(itemId: number) {
      const pos = this.uncollapsedList.indexOf(itemId);
      if (pos !== -1) {
        this.uncollapsedList.splice(pos, 1);
      } else {
        this.uncollapsedList.push(itemId);
      }
    },
    isCollapsed(itemId: number) {
      return !this.uncollapsedList.includes(itemId);
    },
  },
});
