







import Vue from "vue";

import DeviceList from "@/components/telematic_device/DeviceList.vue";

export default Vue.extend({
  name: "HomeView",

  components: {
    DeviceList,
  },
});
