










































































import Vue from "vue";

import {
  AssetTypeInterface,
  ApplicationMeasureKeys,
  SubAssetAssetTypeTemplate,
  SubAssetTemplate,
} from "@/constants/interfaces";
import PrometheusAPI from "@/helpers/prometheus_api";
import { CopyTemplateDetails } from "@/constants/api_interfaces";
import { notificationGroup, notificationType } from "@/constants/constants";

import TemplateModalGeneral, {
  GetMkValue,
} from "@/components/templates/mixins/TemplateModalGeneral";

import ModalWindow from "@/components/common/ModalWindow.vue";
import TemplateModalHeader from "@/components/templates/template_modal/TemplateModalHeader.vue";
import { AssetTypeEditActions } from "@/stores/action_types";
import { AssetTypeEditState } from "@/stores/types";

export default Vue.extend({
  name: "TemplateModalCopy",

  mixins: [TemplateModalGeneral],

  components: {
    ModalWindow,
    TemplateModalHeader,
  },

  props: {
    copyTemplate: {
      type: Object as () => SubAssetAssetTypeTemplate,
      required: true,
    },
    appMkList: {
      type: Array as () => ApplicationMeasureKeys[],
      required: true,
    },
    assetType: {
      type: Object as () => AssetTypeInterface,
      required: true,
    },
    baseSubAsset: {
      type: Object as () => SubAssetTemplate,
      required: false,
    },
  },

  data() {
    return {
      state: this.$store.state.assetTypeEdit as AssetTypeEditState,
      valueList: [] as GetMkValue[],
      selectedAppId: null as null | number,
    };
  },

  methods: {
    checkCopyInputs(): CopyTemplateDetails | undefined {
      if (!this.state.subAssetSuffix) {
        this.$notify({
          group: notificationGroup.general,
          type: notificationType.warn,
          title: `Invalid ${this.$t("sub_asset")} suffix`,
          duration: 5000,
        });

        return;
      }

      if (!this.isSuffixUnique()) {
        this.$notify({
          group: notificationGroup.general,
          type: notificationType.warn,
          title: `The ${this.$t("sub_asset")} suffix (${
            this.state.subAssetSuffix
          }) already exists for this ${this.$t("measure_point")}`,
          duration: 5000,
        });
        return;
      }

      let payload = this.valueList
        .filter(item => item.checked)
        .map(mkVal => {
          return {
            measure_key_id: mkVal.measure_key_id,
            sub_asset_suffix: this.state.subAssetSuffix as string,
            value: mkVal.value,
          };
        });

      if (payload.length === 0) {
        this.$notify({
          group: notificationGroup.general,
          type: notificationType.warn,
          title: `No ${this.$t("measure_keys")} selected`,
          duration: 5000,
        });
        return;
      }

      return payload;
    },

    isSuffixUnique() {
      return this.copyTemplate.sub_assets.every(
        subAsset => subAsset.name !== this.state.subAssetSuffix
      );
    },

    async copyTmp() {
      const payload = this.checkCopyInputs();
      if (!payload) return; // invalid input, user is informed

      await PrometheusAPI.updateTemplateValue(this.copyTemplate.template_id, payload);
      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: `Template copied`,
      });
      this.$emit("modal-save");
    },
  },

  mounted() {
    this.$store.dispatch(AssetTypeEditActions.setSubAssetSuffix, `${this.baseSubAsset.name} copy`);

    // prefill inputs
    for (const app of this.baseSubAsset.details) {
      for (const mk of app.template_details) {
        this.valueList.push({
          ...mk,
          description: mk.measure_key_description,
          application_id: app.application_id,
          checked: true,
          value: String(mk.value),
        });
      }
    }
  },
});
