























import Vue from "vue";

import { ConfigRowValues, EntityInterface } from "@/constants/interfaces";
import { hierarchyLevel } from "@/constants/constants";
import { getHierarchyLevel, getHierarchyId } from "@/helpers/schema_helpers";
import { RootState } from "@/stores/types";
import PrometheusAPI from "@/helpers/prometheus_api";
import { GetNotificationObj } from "@/constants/notifications";

import LevelVisible from "@/components/config_edit/config_table/LevelVisible";

import EditCell from "@/components/common/EditCell.vue";

export default Vue.extend({
  name: "ValueRow",

  mixins: [LevelVisible],

  components: {
    EditCell,
  },

  props: {
    rowData: Object as () => ConfigRowValues,
    mpId: Number,
    hiddenRows: Array as () => String[],
  },

  data: function() {
    return {
      hierarchyLevel,
    };
  },

  computed: {
    canEdit() {
      return (this.$store.state as RootState).isSelectedEntityEditable;
    },
    entity: function() {
      return (this.$store.state as RootState).selectedEntity as EntityInterface;
    },
  },

  methods: {
    getSettingValue(settingHierarchy: hierarchyLevel, targetSetting: ConfigRowValues) {
      if (settingHierarchy === hierarchyLevel.organization) {
        return targetSetting.values.organization;
      } else if (settingHierarchy === hierarchyLevel.fleet) {
        return targetSetting.values.fleet;
      } else if (settingHierarchy === hierarchyLevel.assetGroup) {
        return targetSetting.values.asset_group;
      } else if (settingHierarchy === hierarchyLevel.asset) {
        return targetSetting.values.asset;
      }
    },

    async onSave(value: any) {
      await PrometheusAPI.saveConfigSetting(
        {
          hierarchy_level: getHierarchyLevel(this.entity),
          entity_id: getHierarchyId(this.entity),
        },
        { setting_key_id: this.rowData.setting_key_id, value }
      );
      this.$notify(GetNotificationObj(true, `Success`, `Saved ${this.$t("settings_key")}`));
      this.$emit("keysaved");
    },

    async setDeleteFlag() {
      await PrometheusAPI.deleteConfigSetting(
        {
          hierarchy_level: getHierarchyLevel(this.entity),
          entity_id: getHierarchyId(this.entity),
        },
        this.rowData.setting_key_id
      );
      this.$notify(GetNotificationObj(true, `Success`, `Deleted ${this.$t("settings_key")}`));
      this.$emit("keydeleted");
    },
  },
});
