import {
  orgGuidedSetup,
  fleetGuidedSetup,
  productGroupGuidedSetup,
} from "@/constants/api_interfaces";
import { ValueType } from "@/constants/constants";

// generic helpers

export type Omit<K, T> = Pick<K, Exclude<keyof K, T>>;

// interfaces for vue display
interface BaseRow {
  type: "value" | "application";
  level: number;
  key: string;
  app: {
    id: number;
    name: string;
  };
}

export interface ConfigRowValues extends BaseRow {
  type: "value";
  setting_key_id: number;
  description: string;
  values: {
    root: string;
    organization: string;
    fleet: string;
    asset_group: string;
    asset: string;
    result: string;
  };
}

export interface ConfigRowApp extends BaseRow {
  type: "application";
}

export type ConfigRowInterface = ConfigRowValues | ConfigRowApp;

// application

export interface LoggerApplication {
  name: string;
  application_id: number;
}

// interfaces for Entities
export interface LockData {
  user_id: number;
  user_name: string;
  is_root: boolean;
  is_editable: boolean;
}

export interface OrganizationInterface {
  name: string;
  display_name: string;
  organization_id: number;
  customer_serial: string;
  locks?: LockData[];
}

export interface FleetInterface {
  name: string;
  display_name: string;
  fleet_id: number;
  organization_id: number;
  organization: OrganizationInterface;
  locks?: LockData[];
}

export interface ProductGroupInterface {
  name: string;
  product_group_id: number;
  product_type: ProductType;
  model_name: string;
  model_manufacturer: string | null;
  model_serial: string | null;
}

export interface AssetGroupInterface {
  name: string;
  display_name: string;
  asset_group_id: number;
  asset_group_serial: string;
  fleet_id: number;
  fleet: FleetInterface;
  product_group_id: number;
  product_group: ProductGroupInterface;
  locks?: LockData[];
}

export interface AssetTypeInterface {
  name: string;
  asset_type_id: number;
  model_name: string;
  model_manufacturer: string | null;
  model_serial: string | null;
  asset_type: string;
}

export interface AssetInterface {
  name: string;
  display_name: string;
  asset_serial: string;
  asset_id: number;
  asset_group_id: number;
  asset_group: AssetGroupInterface;
  asset_type_id: number;
  asset_type: AssetTypeInterface;
  uuid: string;
  locks?: LockData[];
}

/** Union interface for entities */
export type EntityInterface =
  | FleetInterface
  | OrganizationInterface
  | AssetGroupInterface
  | AssetInterface
  | AssetTypeInterface
  | ProductGroupInterface;

/** Union interface for lockable entities */
export type LockableEntityInterface =
  | OrganizationInterface
  | FleetInterface
  | AssetGroupInterface
  | AssetInterface;

//Setting keys interfaces

/** settingKey values for a specific entity level */
export interface SettingKeyValues {
  [setting_name: string]: {
    description: string;
    result_value: any;
    setting_key_id: number;
    values: {
      root?: any;
      organization?: any;
      fleet?: any;
      asset_group?: any;
      asset?: any;
    };
  };
}

/** list of setting_keys grouped by application*/
export interface ApplicationSettingValues {
  application_id: number;
  application_name: string;
  setting_keys: SettingKeyValues;
}

/** List of setting keys grouped by application */
export interface ApplicationSettingKeys {
  application_id: number;
  application_name: string;
  setting_keys: SettingKey[];
}

export interface SettingKey {
  setting_key_id: number;
  setting_key_name: string;
  description: string;
  is_asset_level: boolean;
}

// measurekey interfaces

export interface MeasurePoint {
  measure_point_id: number;
  name: string;
  readable_name: null | string;
}

export interface MeasureKey {
  measure_key_id: number;
  measure_key_name: string;
  description: null | string;
  type_checker: ValueType;
}

export interface ApplicationMeasureKeys {
  application_id: number;
  application_name: string;
  measure_keys: MeasureKey[];
}

//Template interfaces

export interface TemplateDetail {
  template_detail_id: number;
  measure_key_id: number;
  measure_key_name: string;
  measure_key_description: string;
  type_checker: ValueType;
  value: any;
}

export interface TemplateInterface {
  application_id: number;
  application_name: string;
  template_details: TemplateDetail[];
}

interface BaseAssetTypeTemplate {
  template_id: number;
  measure_point_id: number;
  measure_point_name: string;
  measure_point_readable_name: string;
  unit?: Omit<Unit, "description">;
}

/** all template details of a single measure point*/
export interface SingleAssetTypeTemplate extends BaseAssetTypeTemplate {
  sub_asset_name: null;
  details: TemplateInterface[];
}

export interface SubAssetTemplate {
  name: string;
  details: TemplateInterface[];
}

// TODO: rename this to something more useful... Sorry again...
export interface SubAssetAssetTypeTemplate extends BaseAssetTypeTemplate {
  sub_asset_name: string;
  sub_assets: SubAssetTemplate[];
}

export type AssetTypeTemplate = SingleAssetTypeTemplate | SubAssetAssetTypeTemplate;

export interface MeasureKeyValue {
  measure_key_value_id: number;
  measure_key_id: number;
  measure_key_name: string;
  measure_key_description: string;
  type_checker: ValueType;
  value: any;
}

/** measure key values grouped by application*/
export interface AppliedTemplateDetails {
  application_id: number;
  application_name: string;
  measure_keys: MeasureKeyValue[];
}

interface BaseAppliedAssetTemplate {
  measure_point_id: number;
  measure_point_name: string;
  measure_point_readable_name: string;
}

// TODO: find a good name for those... Sorry...
export interface SingleAppliedAssetTemplate extends BaseAppliedAssetTemplate {
  unit?: Omit<Unit, "description">;
  details: AppliedTemplateDetails[];
}

export interface AppliedSubAssetTemplate {
  name: string;
  asset_id: number;
  unit?: Omit<Unit, "description">;
  details: AppliedTemplateDetails[];
}

export interface SubAssetAppliedAssetTemplate extends BaseAppliedAssetTemplate {
  sub_assets: AppliedSubAssetTemplate[];
}

export type AppliedAssetTemplate = SingleAppliedAssetTemplate | SubAssetAppliedAssetTemplate;

// Product type

export enum ProductType {
  ship = "ship",
  train = "train",
}

// User interfaces

export enum BackendUserRole {
  admin = "admin",
  zps_engineer = "zps_engineer",
  zps_technician = "zps_technician",
  organization_engineer = "organization_engineer",
  organization_technician = "organization_technician",
}

export enum UserRole {
  admin = BackendUserRole.admin,
  engineer = BackendUserRole.zps_engineer,
  technician = BackendUserRole.zps_technician,
}

export interface UserClaims {
  userName: string;
  role: string;
  organization: number;
  accessToken: string;
  refreshToken: string;
}

export interface User {
  active: boolean;
  organization: null | number;
  email: string;
  role: UserRole;
  user_id: number;
}

// guided setup
export interface ParentsGuidedSetup {
  organization: orgGuidedSetup;
  fleet: fleetGuidedSetup;
  productGroup: productGroupGuidedSetup;
}

export interface Unit {
  unit_id: number;
  name: string;
  symbol: string;
  description: string | undefined;
}

// Device
interface Device {
  hardware_id: string;
  device_type: string;
  device_id: number;
}
export interface AssignedDevice {
  device: Device;
  registered_info: {
    asset_group: { name: string; asset_group_id: number };
    updated_at: string | null;
    created_at: string;
    has_dump: boolean;
  };
}

interface UnassignedDevice {
  device: Device;
  registered_info: {
    asset_group: null;
    updated_at: string | null;
    created_at: string;
    has_dump: boolean;
  };
}

interface UnconnectedDevice {
  device: Device;
  registered_info: null;
}

export enum DeviceStatus {
  Whitelisted = "Whitelisted",
  Connected = "Connected",
  Assigned = "Assigned",
}

export type DeviceInformation = UnassignedDevice | AssignedDevice | UnconnectedDevice;
