// Actual mutation functions

import { MutationTree } from "vuex";

import store from "@/stores/";
import { RootState } from "@/stores/types";
import { EntityInterface } from "@/constants/interfaces";
import { hierarchyLevel } from "@/constants/constants";
import { isLockableEntity } from "@/helpers/schema_helpers";

export const mutations: MutationTree<RootState> = {
  initState(state: RootState) {
    if (window.localStorage.getItem("store")) {
      // Replace the state object with the stored item
      store.replaceState({
        ...state,
        ...JSON.parse(window.localStorage.getItem("store") || "{}"),
      });
    }
  },

  setSelectedEntity(
    state: RootState,
    payload: { selectedEntity: EntityInterface; loggedInUser: string }
  ) {
    const { selectedEntity, loggedInUser } = payload;
    state.selectedEntity = selectedEntity;

    if (!selectedEntity) {
      state.isSelectedEntityEditable = false;
    } else if (!isLockableEntity(selectedEntity)) {
      state.isSelectedEntityEditable = true;
    } else if (!selectedEntity.locks) {
      state.isSelectedEntityEditable = false;
    } else if (
      selectedEntity.locks.some(lock => lock.user_name === loggedInUser && lock.is_editable)
    ) {
      state.isSelectedEntityEditable = true;
    } else state.isSelectedEntityEditable = false;
  },

  setNavHierarchy(state: RootState, level: hierarchyLevel | null) {
    state.navHierarchy = level;
  },
};
