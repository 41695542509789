import Vue from "vue";

import {
  EntityInterface,
  LockableEntityInterface,
  LockData,
  AssetGroupInterface,
  OrganizationInterface,
  FleetInterface,
} from "@/constants/interfaces";
import { hierarchyLevel } from "@/constants/constants";
import { i18n } from "@/plugins/i18n";
import { isCurrentUser } from "./auth_services";

/** Returns the name of the hierarchy for API endpoints */
export function getHierarchyEndpoint(hierarchyOrEntity: hierarchyLevel | EntityInterface): string {
  let level: hierarchyLevel;

  if (typeof hierarchyOrEntity === "string") {
    level = hierarchyOrEntity;
  } else {
    level = getHierarchyLevel(hierarchyOrEntity);
  }

  switch (level) {
    case hierarchyLevel.organization:
      return "organizations";
    case hierarchyLevel.fleet:
      return "fleets";
    case hierarchyLevel.assetGroup:
      return "asset-groups";
    case hierarchyLevel.asset:
      return "assets";
    case hierarchyLevel.productGroup:
      return "product-groups";
    case hierarchyLevel.assetType:
      return "asset-types";
  }
}

/** Returns the hierarchyLevel of a given entity */
export function getHierarchyLevel(entity: EntityInterface): hierarchyLevel {
  if ("asset_id" in entity) {
    return hierarchyLevel.asset;
  } else if ("product_group_id" in entity && "asset_group_id" in entity) {
    return hierarchyLevel.assetGroup;
  } else if ("fleet_id" in entity && "organization_id" in entity) {
    return hierarchyLevel.fleet;
  } else if ("product_group_id" in entity) {
    return hierarchyLevel.productGroup;
  } else if ("asset_type_id" in entity) {
    return hierarchyLevel.assetType;
  } else if ("organization_id" in entity) {
    return hierarchyLevel.organization;
  } else {
    throw "Incorrect argument: " + JSON.stringify(entity, null, 2);
  }
}

/** Returns the id of the passed hierarchyItem */
export function getHierarchyId(entity: EntityInterface): number {
  const level = getHierarchyLevel(entity);
  const typeless_entity = entity as any;

  if (level === hierarchyLevel.assetType) return typeless_entity.asset_type_id;
  else if (level === hierarchyLevel.productGroup) return typeless_entity.product_group_id;
  else if (level === hierarchyLevel.asset) return typeless_entity.asset_id;
  else if (level === hierarchyLevel.assetGroup) return typeless_entity.asset_group_id;
  else if (level === hierarchyLevel.fleet) return typeless_entity.fleet_id;
  else if (level === hierarchyLevel.organization) return typeless_entity.organization_id;
  else throw "Incorrect argument: " + JSON.stringify(typeless_entity, null, 2);
}

export function getHierarchyIdName(obj: { level: hierarchyLevel }): string;
export function getHierarchyIdName(obj: { entity: EntityInterface }): string;
/** Returns the id of the passed hierarchyItem */
export function getHierarchyIdName({
  entity,
  level,
}: {
  entity?: EntityInterface;
  level?: hierarchyLevel;
}): string {
  const chosenLevel = level || getHierarchyLevel(entity as EntityInterface);

  if (chosenLevel === hierarchyLevel.assetType) return "asset_type_id";
  else if (chosenLevel === hierarchyLevel.productGroup) return "product_group_id";
  else if (chosenLevel === hierarchyLevel.asset) return "asset_id";
  else if (chosenLevel === hierarchyLevel.assetGroup) return "asset_group_id";
  else if (chosenLevel === hierarchyLevel.fleet) return "fleet_id";
  else if (chosenLevel === hierarchyLevel.organization) return "organization_id";
  else throw "Incorrect argument: " + JSON.stringify({ entity, level }, null, 2);
}

/** Returns the parent hierarchyLevel if one exists (otherwhise null) */
export function getParentLevel(hierarchy: hierarchyLevel) {
  if (hierarchy === hierarchyLevel.fleet) return hierarchyLevel.organization;
  else if (hierarchy === hierarchyLevel.assetGroup) return hierarchyLevel.fleet;
  else if (hierarchy === hierarchyLevel.asset) return hierarchyLevel.assetGroup;
  else return null;
}

export function getChildLevel(parentHierarchy: hierarchyLevel) {
  switch (parentHierarchy) {
    case hierarchyLevel.organization:
      return hierarchyLevel.fleet;
    case hierarchyLevel.fleet:
      return hierarchyLevel.assetGroup;
    case hierarchyLevel.assetGroup:
      return hierarchyLevel.asset;
    case hierarchyLevel.productGroup:
      return hierarchyLevel.assetGroup;
    case hierarchyLevel.assetType:
      return hierarchyLevel.asset;
  }
}

/** Returns the parent entity if one exists (otherwhise null) */
export function getParent(
  entity: EntityInterface
): null | AssetGroupInterface | FleetInterface | OrganizationInterface {
  const level = getHierarchyLevel(entity);
  const typeless_entity = entity as any;

  if (level === hierarchyLevel.assetType) return null;
  else if (level === hierarchyLevel.productGroup) return null;
  else if (level === hierarchyLevel.asset) return typeless_entity.asset_group;
  else if (level === hierarchyLevel.assetGroup) return typeless_entity.fleet;
  else if (level === hierarchyLevel.fleet) return typeless_entity.organization;
  else if (level === hierarchyLevel.organization) return null;
  else throw "Incorrect argument: " + JSON.stringify(typeless_entity, null, 2);
}

/** Returns the class entity if one exists (otherwhise null) */
export function getClass(entity: EntityInterface) {
  const level = getHierarchyLevel(entity);
  const typeless_entity = entity as any;

  if (level === hierarchyLevel.assetType) return null;
  else if (level === hierarchyLevel.productGroup) return null;
  else if (level === hierarchyLevel.asset) return typeless_entity.asset_type;
  else if (level === hierarchyLevel.assetGroup) return typeless_entity.product_group;
  else if (level === hierarchyLevel.fleet) return null;
  else if (level === hierarchyLevel.organization) return null;
  else throw "Incorrect argument: " + JSON.stringify(typeless_entity, null, 2);
}

/** Returns the class hierarchyLevel if one exists (otherwhise null) */
export function getTemplateLevel(hierarchy: hierarchyLevel) {
  if (hierarchy === hierarchyLevel.assetGroup) return hierarchyLevel.productGroup;
  else if (hierarchy === hierarchyLevel.asset) return hierarchyLevel.assetType;
  else return null;
}

/** Returns a human readable name of the provided hierarchy */
export function getHierarchyName(hierarchy: hierarchyLevel | null): string {
  if (hierarchy === hierarchyLevel.fleet) return i18n.t("fleet") as string;
  else if (hierarchy === hierarchyLevel.organization) return i18n.t("organization") as string;
  else if (hierarchy === hierarchyLevel.productGroup) return i18n.t("product_group") as string;
  else if (hierarchy === hierarchyLevel.assetType) return i18n.t("asset_type") as string;
  else if (hierarchy === hierarchyLevel.assetGroup) return i18n.t("asset_group") as string;
  else if (hierarchy === hierarchyLevel.asset) return i18n.t("asset") as string;
  else if (!hierarchy) return "";
  else {
    throw Error(`Unexhausted if condition in getHierarchyName: ${JSON.stringify(hierarchy)}`);
  }
}

export function isTemplateLevel(targetHierarchyLevel: hierarchyLevel) {
  return (
    targetHierarchyLevel === hierarchyLevel.productGroup ||
    targetHierarchyLevel === hierarchyLevel.assetType
  );
}

export function isLockEntityEditable(locks: LockData[] | undefined, vueInstance: Vue): boolean {
  if (!locks) return false;
  else {
    return locks.some(lock => lock.is_editable && isCurrentUser(lock.user_name, vueInstance));
  }
}

export function canEditEntityConfig(this: Vue, entity: LockableEntityInterface) {
  if (!entity.locks) return false;
  else return isLockEntityEditable(entity.locks, this);
}

export function isLockableEntity(entity: EntityInterface): entity is LockableEntityInterface {
  switch (getHierarchyLevel(entity)) {
    case hierarchyLevel.organization:
    case hierarchyLevel.fleet:
    case hierarchyLevel.assetGroup:
    case hierarchyLevel.asset:
      return true;
    default:
      return false;
  }
}
