import {
  AssetTypeTemplate,
  AppliedAssetTemplate,
  SubAssetAppliedAssetTemplate,
  SubAssetTemplate,
  AppliedSubAssetTemplate,
} from "@/constants/interfaces";

/* Check if the argument is a Template (in contrast to a MeasureKeyValue/AppliedTempalte) */
export function isTemplate(
  templateOrMeasureKeyValue: AssetTypeTemplate | AppliedAssetTemplate
): templateOrMeasureKeyValue is AssetTypeTemplate {
  return "template_id" in templateOrMeasureKeyValue;
}

export function isTemplateSubAsset(
  subAsset: SubAssetTemplate | AppliedSubAssetTemplate
): subAsset is SubAssetTemplate {
  return !("asset_id" in subAsset);
}

export function isSubAssetAppliedTemplate(
  appliedTemplate: AppliedAssetTemplate
): appliedTemplate is SubAssetAppliedAssetTemplate {
  return "sub_assets" in appliedTemplate;
}
