

































































import Vue from "vue";

import { AssetTypeInterface } from "@/constants/interfaces";
import PrometheusAPI from "@/helpers/prometheus_api";
import { parseStringToValue } from "@/helpers/global_helpers";
import { AddTemplate } from "@/constants/api_interfaces";
import { notificationGroup, notificationType, DEFAULT_UNIT } from "@/constants/constants";
import { AssetTypeEditState } from "@/stores/types";
import { AssetTypeEditActions } from "@/stores/action_types";

import TemplateModalGeneral, {
  GetMkValue,
} from "@/components/templates/mixins/TemplateModalGeneral";

import ModalWindow from "@/components/common/ModalWindow.vue";
import TemplateModalHeader from "@/components/templates/template_modal/TemplateModalHeader.vue";

export default Vue.extend({
  name: "TemplateModalAdd",

  mixins: [TemplateModalGeneral],

  components: {
    ModalWindow,
    TemplateModalHeader,
  },

  props: {
    assetType: {
      type: Object as () => AssetTypeInterface,
      required: true,
    },
  },

  data() {
    return {
      state: this.$store.state.assetTypeEdit as AssetTypeEditState,
      valueList: [] as GetMkValue[],
      selectedAppId: null as null | number,
    };
  },

  methods: {
    generatePayload(): AddTemplate {
      const measurePoint = this.state.measurePointList!.find(
        mp => mp.name === this.state.measurePointName
      );
      if (!measurePoint) {
        throw {
          name: "InputCheckError",
          message: `Invalid ${this.$t("measure_point")} selected`,
        };
      }

      if (this.state.subAssetName) {
        if (!this.state.subAssetSuffix) {
          throw {
            name: "InputCheckError",
            message: `Invalid ${this.$t("sub_asset")} suffix`,
          };
        }
      }

      if (this.state.unitName && this.state.unitName !== DEFAULT_UNIT.name) {
        var unit = this.state.unitList!.find(unit => unit.name === this.state.unitName);
        if (!unit) {
          throw {
            name: "InputCheckError",
            message: `Unit ${this.state.unitName} is not known`,
          };
        }
      }

      let payload: AddTemplate = {
        measure_point_id: measurePoint.measure_point_id,
        unit_id: unit ? unit.unit_id : undefined,
        sub_asset_name: this.state.subAssetName || undefined,
        sub_asset_suffix: this.state.subAssetName ? this.state.subAssetSuffix : undefined,
        measure_keys: this.valueList
          .filter(item => item.checked)
          .map(mkVal => {
            let value: string | number;
            try {
              value = parseStringToValue(mkVal.value.trim(), mkVal.type_checker);
            } catch (error) {
              throw {
                name: "InputCheckError",
                message: `Invalid value for ${mkVal.measure_key_name}: ${error}`,
              };
            }
            return {
              measure_key_id: mkVal.measure_key_id,
              value,
            };
          }),
      };

      if (payload.measure_keys.length === 0) {
        throw {
          name: "InputCheckError",
          message: `No ${this.$t("measure_keys")} selected`,
        };
      }

      return payload;
    },

    async addTmp() {
      try {
        var payload = this.generatePayload();
      } catch (error) {
        this.$notify({
          group: notificationGroup.general,
          type: notificationType.error,
          title: error.message,
          duration: 5000,
        });
        return;
      }

      try {
        await PrometheusAPI.addNewTemplate(this.assetType.asset_type_id, payload);
      } catch (_) {
        return;
      }

      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: `Template saved`,
      });
      this.$emit("modal-save");
    },
  },

  async created() {
    if (!this.state.measurePointList) {
      await this.$store.dispatch(AssetTypeEditActions.getMeasurePoints);
    }
  },
});
