import Vue from "vue";

import { hierarchyLevel } from "@/constants/constants";
import { EntityInterface } from "@/constants/interfaces";
import {
  getHierarchyLevel,
  isLockEntityEditable,
  isLockableEntity,
} from "@/helpers/schema_helpers";

export default Vue.extend({
  methods: {
    /** Check if the entity can be edited by the logged in user
     * First check is for overall entity edit rights
     * Second check if for locking: if the entity can be locked it needs to be locked for editing
     */
    canEditEntity(entity: EntityInterface) {
      if (!this.$store.getters["entity/hasEntityEditRights"]) return false;

      if (!isLockableEntity(entity)) return true;

      if (isLockEntityEditable(entity.locks, this)) {
        return true;
      } else return false;
    },

    /** Check if the entity can be deleted by the logged in user
     * First check is for overall entity edit rights
     * Second check if for locking
     */
    canDeleteEntity(entity: EntityInterface) {
      if (!this.$store.getters["entity/hasEntityEditRights"]) return false;

      if (!isLockableEntity(entity)) return true;
      else if (getHierarchyLevel(entity) === hierarchyLevel.asset) {
        return isLockEntityEditable(entity.locks, this);
      } else {
        return !entity.locks;
      }
    },
  },
});
