

























import Vue from "vue";

import { AssetGroupInterface } from "@/constants/interfaces";
import { downloadInstallationFile } from "@/components/guided_setup/download";
import { notificationType, notificationGroup } from "@/constants/constants";

import ModalWindow from "@/components/common/ModalWindow.vue";
import LoadingSpinner from "@/components/common/LoadingSpinner.vue";

export default Vue.extend({
  name: "DownloadPrompt",

  components: {
    ModalWindow,
    LoadingSpinner,
  },

  props: {
    assetGroup: Object as () => AssetGroupInterface,
  },

  data() {
    return {
      loading: false,
      prompt: "",
    };
  },
  methods: {
    onCancel() {
      this.$emit("modal-hide");
    },

    async onConfirm() {
      if (this.loading) {
        this.$notify({
          title: "Still loading",
          type: notificationType.warn,
          group: notificationGroup.general,
        });
        return;
      }

      if (this.prompt !== this.assetGroup.name) {
        this.$notify({
          title: "Error",
          text: `The entered value does not match the selected ${this.$t("asset_group")}`,
          type: notificationType.error,
          group: notificationGroup.general,
        });
      } else {
        this.loading = true;
        await downloadInstallationFile(this.assetGroup.asset_group_id, this.assetGroup.name, this);
        this.loading = false;
      }

      this.$emit("modal-hide");
    },
  },
});
