

















import Vue from "vue";

import { DeviceInformation, DeviceStatus, AssetGroupInterface } from "@/constants/interfaces";
import { notificationGroup, notificationType } from "@/constants/constants";
import { EventBus } from "@/components/telematic_device/event_bus.ts";
import PrometheusAPI from "@/helpers/prometheus_api";

import AssetGroupSelectModal from "@/components/common/AssetGroupSelectModal.vue";

export default Vue.extend({
  name: "AssetGroupCell",

  components: {
    AssetGroupSelectModal,
  },

  props: {
    device: {
      type: Object as () => DeviceInformation,
      required: true,
    },
  },

  data: function () {
    return {
      deviceStatus: DeviceStatus,
      isSelectModalVisible: false,
      deviceToAssign: null as DeviceInformation | null,
    };
  },

  computed: {
    assetGroup: function (): string {
      return this.isAssigned ? this.device!.registered_info!.asset_group!.name : "";
    },
    isAssigned() {
      if (this.device.registered_info) return Boolean(this.device.registered_info.asset_group);
      return false;
    },
    isUnconnectedDevice: function () {
      return !this.device.registered_info;
    },
  },

  methods: {
    showSelectModal(registeredDevice: DeviceInformation) {
      this.isSelectModalVisible = true;
      this.deviceToAssign = registeredDevice;
    },
    hideSelectModal() {
      this.isSelectModalVisible = false;
      this.deviceToAssign = null;
    },
    async assignAssetGroup(assetGroup: AssetGroupInterface) {
      this.isSelectModalVisible = false;

      await PrometheusAPI.assignDevice(
        this.deviceToAssign!.device.device_id,
        assetGroup.asset_group_id
      );

      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: `Device ${this.deviceToAssign!.device.hardware_id} assigned`,
      });
      EventBus.$emit("update-device-list");
    },
  },
});
