import Vue from "vue";
import VueI18n from "vue-i18n";
import { Language } from "@/constants/constants";
import en from "@/lang/en.json";

// This Plugin is separate (from the other registers in main.ts)
// to allow using it in pure Javascript files separately as well (outside of Vue context)

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: Language.defaultLanguage,
  fallbackLocale: Language.fallbackLanguage,
  messages: { en },
});
