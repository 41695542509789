




































import Vue from "vue";

import { AssetGroupInterface } from "@/constants/interfaces";
import PrometheusAPI from "@/helpers/prometheus_api";
import { notificationGroup, notificationType, hierarchyLevel } from "@/constants/constants";
import { strSearchItem } from "@/helpers/global_helpers";

import ModalWindow from "@/components/common/ModalWindow.vue";
import TextFilter from "@/components/common/TextFilter.vue";

export default Vue.extend({
  name: "AssetGroupSelectModal",

  components: {
    ModalWindow,
    TextFilter,
  },

  data() {
    return {
      allAssetGroups: [] as AssetGroupInterface[],
      selectedAssetGroup: null as AssetGroupInterface | null,
      filterText: "",
    };
  },

  computed: {
    filteredAssetGroups(): AssetGroupInterface[] {
      if (!this.filterText) return this.allAssetGroups;
      else {
        return this.allAssetGroups.filter(assetGroup => strSearchItem(this.filterText, assetGroup));
      }
    },
  },

  methods: {
    isSelected(assetGroup: AssetGroupInterface) {
      if (!this.selectedAssetGroup) return false;
      else return this.selectedAssetGroup.asset_group_id === assetGroup.asset_group_id;
    },
    selectAssetGroup(assetGroup: AssetGroupInterface) {
      this.selectedAssetGroup = assetGroup;
    },
    updateFilter(filterText: string) {
      this.filterText = filterText;

      if (!strSearchItem(this.filterText, this.selectedAssetGroup)) {
        this.selectedAssetGroup = null;
      }
    },
    onCancel() {
      this.filterText = "";
      this.selectedAssetGroup = null;
      this.$emit("modal-hide");
    },
    async onConfirm() {
      if (this.selectedAssetGroup) {
        this.$emit("asset-group-select", this.selectedAssetGroup);

        this.filterText = "";
        this.selectedAssetGroup = null;
        return;
      }

      this.$notify({
        title: `No ${this.$t("asset_group")} selected`,
        group: notificationGroup.general,
        type: notificationType.warn,
      });
    },
    fixTableColumnWidth() {
      for (const th of this.$el.querySelectorAll("thead th")) {
        (th as HTMLTableHeaderCellElement).style.width = `${th.scrollWidth}px`;
      }
    },
  },

  async mounted() {
    this.allAssetGroups = await PrometheusAPI.getHierarchyNames(hierarchyLevel.assetGroup);

    // wait for content to be updated
    await this.$nextTick();
    this.fixTableColumnWidth();
  },
});
