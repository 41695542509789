
































import Vue from "vue";

import { RootState } from "@/stores/types";
import { AssetInterface, AssetTypeTemplate, AppliedAssetTemplate } from "@/constants/interfaces";
import PrometheusAPI from "@/helpers/prometheus_api";
import { strSearchItem } from "@/helpers/global_helpers";
import { canEditEntityConfig } from "@/helpers/schema_helpers";

import AvailableTemplateTable from "@/components/templates/AvailableTemplateTable.vue";
import AppliedTemplateTable from "@/components/templates/AppliedTemplateTable.vue";
import TextFilter from "@/components/common/TextFilter.vue";

export default Vue.extend({
  name: "AssetTemplates",

  components: {
    AppliedTemplateTable,
    AvailableTemplateTable,
    TextFilter,
  },

  data() {
    return {
      asset: (this.$store.state as RootState).selectedEntity as AssetInterface,
      appliedTemplates: [] as AppliedAssetTemplate[],
      appliedFilter: "",
      allAssetTemplates: [] as AssetTypeTemplate[],
      availableFilter: "",
    };
  },

  computed: {
    isEditable: function() {
      return this.canEditEntityConfig((this.$store.state as RootState)
        .selectedEntity as AssetInterface);
    },
    availableTemplates: function() {
      return this.allAssetTemplates.filter(
        available =>
          !this.appliedTemplates.some(
            applied => available.measure_point_id === applied.measure_point_id
          )
      );
    },
    filteredApplied() {
      const filter = this.appliedFilter;
      if (!filter) return this.appliedTemplates;
      else {
        return this.appliedTemplates.filter(template => strSearchItem(filter, template));
      }
    },
    filteredAvailable() {
      const filter = this.availableFilter;
      if (!filter) return this.availableTemplates;
      else {
        return this.availableTemplates.filter(template => strSearchItem(filter, template));
      }
    },
  },

  methods: {
    canEditEntityConfig,

    onApply(appliedMP: AppliedAssetTemplate) {
      this.appliedTemplates.push(appliedMP);
    },

    onRemove(appliedMP: AppliedAssetTemplate) {
      this.appliedTemplates = this.appliedTemplates.filter(
        temp => temp.measure_point_id !== appliedMP.measure_point_id
      );
    },

    editTemplate(template: AppliedAssetTemplate) {
      const index = this.appliedTemplates.findIndex(
        tmp => tmp.measure_point_id === template.measure_point_id
      );

      if ("sub_assets" in template) {
        var isTemplateEmpty = template.sub_assets.every(subAsset =>
          subAsset.details.every(subAssetDetail => subAssetDetail.measure_keys.length === 0)
        );
      } else {
        var isTemplateEmpty = template.details.every(detail => detail.measure_keys.length === 0);
      }

      if (isTemplateEmpty) {
        this.appliedTemplates.splice(index, 1);
      } else {
        this.appliedTemplates.splice(index, 1, template);
      }
    },

    async updateAppliedTemplates() {
      this.appliedTemplates = await PrometheusAPI.getAssetAppliedTemplates(this.asset.asset_id);
    },

    async updateTemplates() {
      await this.updateAppliedTemplates();
      this.allAssetTemplates = await PrometheusAPI.getAssetTemplates(this.asset.asset_type_id);
    },

    updateAppliedFilter(filterValue: string) {
      this.appliedFilter = filterValue;
    },
    updateAvailableFilter(filterValue: string) {
      this.availableFilter = filterValue;
    },
  },

  mounted: async function() {
    await this.updateTemplates();
  },
});
