import { Module } from "vuex";
// own packages
import { mutations } from "@/stores/login/mutations";
import { actions } from "@/stores/login/actions";
import { LoginState, RootState } from "@/stores/types";
import { getters } from "@/stores/login/getters";

try {
  const storedState = JSON.parse(window.localStorage.getItem("store") as string);
  var baseLoginState: LoginState = storedState.login;
} catch (_) {
  var baseLoginState: LoginState = {
    userClaims: null,
  };
}

export { baseLoginState };

export default {
  namespaced: true,
  state: baseLoginState,
  mutations,
  actions,
  getters,
} as Module<LoginState, RootState>;
