













































































import Vue from "vue";

import PrometheusAPI from "@/helpers/prometheus_api";
import { AssetTypeTemplate } from "@/constants/interfaces";
import { notificationType, notificationGroup } from "@/constants/constants";

import CollapseList from "@/mixins/CollapseList";
import TableHeaderNames from "@/components/templates/mixins/TableHeaderNames";
import SubAssetCollapseList from "@/components/templates/mixins/SubAssetCollapseList";

import TemplateDetail from "@/components/templates/TemplateDetail.vue";

export default Vue.extend({
  name: "AvailableTemplateTable",

  mixins: [TableHeaderNames, CollapseList, SubAssetCollapseList],

  components: {
    TemplateDetail,
  },

  props: {
    templates: Array as () => AssetTypeTemplate[],
    assetId: Number,
    isEditable: Boolean,
  },

  data() {
    return {};
  },

  methods: {
    async applyTemplate(template: AssetTypeTemplate) {
      try {
        var result = await PrometheusAPI.applyAssetTemplate(this.assetId, template.template_id);
      } catch (_) {
        return;
      }

      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: `Successfully applied: ${template.measure_point_name}`,
      });
      this.$emit("applytemplate", result);
    },
  },
});
