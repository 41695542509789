












import Vue from "vue";

import IconBase from "@/components/common/IconBase.vue";
import ArrowRight from "@/components/common/icons/ArrowRight.vue";

export default Vue.extend({
  name: "CollapseIcon",

  components: {
    IconBase,
    ArrowRight,
  },

  props: { collapsed: Boolean, size: { type: [Number, String], default: 24 } },
});
