import { Module } from "vuex";

import { mutations } from "@/stores/asset_type_edit/mutations";
import { actions } from "@/stores/asset_type_edit/actions";
import { RootState } from "@/stores/types";
import { baseState, ModuleState } from "./state";

export default {
  namespaced: true,
  state: baseState,
  mutations,
  actions,
  getter: {},
} as Module<ModuleState, RootState>;
