










import Vue from "vue";

import { MeasurePoint } from "@/constants/interfaces";
import PrometheusAPI from "@/helpers/prometheus_api";
import { strSearchItem } from "@/helpers/global_helpers";

import MeasurePointsHeader from "@/components/measure_points/MeasurePointsHeader.vue";
import MeasurePointsTable from "@/components/measure_points/MeasurePointsTable.vue";

export default Vue.extend({
  name: "MeasurePoints",

  components: {
    MeasurePointsHeader,
    MeasurePointsTable,
  },

  data() {
    return {
      measurePointList: [] as MeasurePoint[],
      listFilter: "",
    };
  },

  computed: {
    filteredMpList: function() {
      const filter = this.listFilter;
      if (!filter) return this.measurePointList;
      else {
        return this.measurePointList.filter(mp => strSearchItem(filter, mp));
      }
    },
  },

  methods: {
    updateFilter(value: string) {
      this.listFilter = value;
    },
    async setMeasurePoints() {
      this.measurePointList = (await PrometheusAPI.getMeasurePoints()).sort(
        (a, b) => a.measure_point_id - b.measure_point_id
      );
    },
  },

  async mounted() {
    this.setMeasurePoints();
  },
});
