

























































































import Vue from "vue";

import PrometheusAPI from "@/helpers/prometheus_api";
import {
  OrganizationInterface,
  FleetInterface,
  ProductGroupInterface,
  ParentsGuidedSetup,
} from "@/constants/interfaces";
import { hierarchyLevel } from "@/constants/constants";
import { getHierarchyName, isLockEntityEditable } from "@/helpers/schema_helpers";
import {
  orgGuidedSetup,
  fleetGuidedSetup,
  productGroupGuidedSetup,
} from "../../constants/api_interfaces";

export default Vue.extend({
  name: "ParentSetup",

  data() {
    return {
      hierarchyLevel,
      organizations: [] as OrganizationInterface[],
      fleets: [] as FleetInterface[],
      productGroups: [] as ProductGroupInterface[],
      orgName: "",
      orgSerial: "",
      fleetName: "",
      productGroupName: "",
      productGroupModelName: "",
      filterFleets: [] as FleetInterface[],
      isFleetDisabled: true,
    };
  },

  watch: {
    async orgName(newVal, oldVal) {
      if (newVal) {
        this.filterFleets = this.fleets.filter(fleet => fleet.organization.name === this.orgName);
        this.isFleetDisabled = false;
      } else {
        this.fleetName = "";
      }
    },
  },

  computed: {
    isOrgWarningVisible: function() {
      if (!this.orgName) return false;

      const _self = this;
      return !this.organizations.some(org => _self.orgName === org.name);
    },

    isFleetWarningVisible: function() {
      if (!this.fleetName) return false;

      const _self = this;
      return !this.filterFleets.some(fleet => _self.fleetName === fleet.name);
    },

    isProductGroupWarningVisible: function() {
      if (!this.productGroupName) return false;

      const _self = this;
      return !this.productGroups.some(ship => _self.productGroupName === ship.name);
    },
  },

  methods: {
    getWarningMsg(entityLevel: hierarchyLevel) {
      return `Info: A new ${getHierarchyName(entityLevel)} will be created!`;
    },

    getParentSetupData() {
      const org = this.organizations.find(org => org.name == this.orgName);
      let organization: orgGuidedSetup;
      if (org) {
        organization = {
          organization_id: org.organization_id,
        };
      } else {
        organization = {
          name: this.orgName,
          customer_serial: this.orgSerial,
        };
      }

      const fl = this.fleets.find(fleet => fleet.name == this.fleetName);
      let fleet: fleetGuidedSetup;
      if (fl) {
        fleet = {
          fleet_id: fl.fleet_id,
        };
      } else {
        fleet = {
          name: this.fleetName,
        };
      }

      const pg = this.productGroups.find(pg => pg.name == this.productGroupName);
      let productGroup: productGroupGuidedSetup;
      if (pg) {
        productGroup = {
          product_group_id: pg.product_group_id,
        };
      } else {
        productGroup = {
          name: this.productGroupName,
          model_name: this.productGroupModelName,
        };
      }

      return {
        organization,
        fleet,
        productGroup,
      } as ParentsGuidedSetup;
    },
  },

  async created() {
    const allOrgs = (await PrometheusAPI.getHierarchyNames(
      hierarchyLevel.organization
    )) as OrganizationInterface[];

    this.organizations = allOrgs.filter(org => {
      return isLockEntityEditable(org.locks, this);
    });

    this.fleets = (await PrometheusAPI.getHierarchyNames(hierarchyLevel.fleet)) as FleetInterface[];
    this.productGroups = (await PrometheusAPI.getHierarchyNames(
      hierarchyLevel.productGroup
    )) as ProductGroupInterface[];
  },
});
