import { LoginState } from "@/stores/types";

export const getters = {
  isLoggedIn: (loginState: LoginState) => !!loginState.userClaims,
  user: (loginState: LoginState) => {
    if (loginState.userClaims)
      return {
        userName: loginState.userClaims.userName,
        role: loginState.userClaims.role,
      };
    else return null;
  },
};
