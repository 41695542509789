





























import Vue from "vue";

import { notificationGroup, notificationType } from "@/constants/constants";
import { AssetTypeEditState } from "@/stores/types";
import { CreateUnit } from "@/constants/api_interfaces";
import { AssetTypeEditActions } from "@/stores/action_types";
import PrometheusAPI from "@/helpers/prometheus_api";

import ModalWindow from "@/components/common/ModalWindow.vue";

export default Vue.extend({
  name: "UnitAddModal",

  components: {
    ModalWindow,
  },

  props: {
    initialName: String,
  },

  data() {
    return {
      state: this.$store.state.assetTypeEdit as AssetTypeEditState,
      unitName: this.initialName || "",
      unitSymbol: "",
      unitDescription: "",
    };
  },

  methods: {
    onCancel() {
      this.$emit("modal-finished");
    },

    async onConfirm() {
      try {
        var newUnit = this.inputCheck();
      } catch (error) {
        this.$notify({
          group: notificationGroup.general,
          type: notificationType.warn,
          title: error.message,
          duration: 5000,
        });
        return;
      }

      try {
        var unitFromDatabase = await PrometheusAPI.addUnit(newUnit);
      } catch (_) {
        return;
      }

      this.$notify({
        group: notificationGroup.general,
        type: notificationType.success,
        title: "Saved New Unit",
      });

      this.$store.dispatch(AssetTypeEditActions.addUnitToUnitList, unitFromDatabase);

      this.$emit("modal-finished");
    },

    inputCheck(): CreateUnit {
      if (!this.unitName) {
        throw {
          name: "InputCheckError",
          message: "Invalid Unit Name",
        };
      }

      if (!this.unitSymbol) {
        throw {
          name: "InputCheckError",
          message: "Invalid Unit Symbol",
        };
      }

      return {
        name: this.unitName,
        symbol: this.unitSymbol,
        description: this.unitDescription || undefined,
      };
    },
  },

  async created() {
    if (!this.state.unitList) {
      await this.$store.dispatch(AssetTypeEditActions.getUnits);
    }
  },
});
